<section class="summary-container" *ngIf="existsSummary()">
	<div class="wrapper">
		<div class="summary-content">
			<h2>Sommario</h2>

			<div class="summary-links">
				<ng-container *ngFor="let section of page?.sections">
					<div
						class="summary-element"
						*ngIf="section?.title && section?.type !== 'component'"
						(click)="navigateTo(toolsService.sanitize(section?.title || ''))">
						{{ section?.title }}
					</div>
				</ng-container>
				<div class="summary-element" (click)="navigateTo('related')" *ngIf="page?.related?.length > 0">
					Altri progetti interessanti
				</div>
			</div>
		</div>
	</div>

	<!-- DIVIDER -->
	<app-divider [hide]="page?.url"></app-divider>
	<!-- END DIVIDER -->
</section>

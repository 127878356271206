<div class="preventivo-placeholder" [id]="componentID"></div>
<div class="preventivo-container">
	<div class="preventivo-col-sx">
		<div
			class="preventivo-step"
			[class.active]="actualStep === 0"
			[class.completed]="actualStep > 0"
			(click)="goToStep('initial')">
			<img *ngIf="actualStep > 0" src="assets/icons/asterisk-white.svg" />
			<img *ngIf="actualStep === 0" src="assets/icons/asterisk.svg" />
		</div>

		<div
			class="preventivo-step"
			[class.completed]="
				actualStep !== step?.step && (isCompleted(step, actualStep > step?.step) || isFilled(step))
			"
			[class.active]="actualStep === step?.step"
			(click)="goToStep(step)"
			*ngFor="let step of steps">
			{{ step?.step }}
		</div>

		<div
			class="preventivo-step"
			[class.active]="actualStep > steps?.length"
			[class.completed]="actualStep <= steps?.length && isCompleted()"
			(click)="goToStep('final')">
			<img *ngIf="actualStep <= steps?.length" src="assets/icons/list-white.svg" />
			<img *ngIf="actualStep > steps?.length" src="assets/icons/list.svg" />
		</div>
	</div>
	<div class="preventivo-col-dx">
		<ng-container *ngIf="actualStep === 0">
			<ng-container *ngTemplateOutlet="initialStepTemplate"></ng-container>
		</ng-container>
		<ng-container *ngFor="let step of steps">
			<ng-container *ngTemplateOutlet="stepTemplate; context: { step }"></ng-container>
		</ng-container>
		<ng-container *ngIf="actualStep > steps?.length">
			<ng-container *ngTemplateOutlet="finalStepTemplate"></ng-container>
		</ng-container>
	</div>
</div>

<!--===============================================================
# STEP TEMPLATE
================================================================-->
<ng-template #stepTemplate let-step="step">
	<div class="options-container" *ngIf="step?.step === actualStep">
		<div class="options-header">
			<h2>{{ step?.title }}</h2>
			<div *ngIf="step?.required && !step?.multi" class="info">Scegli almeno un'opzione per proseguire</div>
			<div *ngIf="step?.required && step?.multi" class="info">Scegli una o più opzioni per proseguire</div>
			<div *ngIf="!step?.required && !step.multi" class="info">Scegli una tra le opzioni proposte</div>
			<div *ngIf="!step?.required && step.multi" class="info">
				Puoi scegliere più di una tra le opzioni proposte
			</div>
		</div>

		<div class="options">
			<div
				*ngFor="let option of step?.options"
				class="option"
				[class.active]="option?.selected"
				(click)="selectOption(step, option)">
				<div class="title">{{ option.text }}</div>
				<div *ngIf="option?.description" class="description">{{ option?.description }}</div>
			</div>
		</div>

		<div class="buttons">
			<button
				type="button"
				class="danger"
				[disabled]="!isSelectable(step, 'prev')"
				(click)="goToStep(step, 'prev')">
				<img src="assets/icons/chevron-left-white.svg" />
			</button>
			<button
				*ngIf="actualStep <= steps.length"
				type="button"
				class="success w-100"
				[disabled]="!isSelectable(step, 'next')"
				(click)="goToStep(step, 'next')">
				{{ step?.step !== steps?.length ? 'Vai allo step successivo' : 'Vai al riepilogo' }}
				<img src="assets/icons/chevron-right-white.svg" />
			</button>
		</div>
	</div>
</ng-template>

<!--===============================================================
# INITIAL STEP TEMPLATE
================================================================-->
<ng-template #initialStepTemplate>
	<div class="options-container recap-container">
		<div class="options-header">
			<h2>Progetta il tuo sito web</h2>
			<div class="info">Grazie a questo strumento, potrai generare autonomamente il tuo preventivo!</div>
		</div>

		<div class="options-body">
			<div class="text text-center">
				Il preventivo
				<u>non è vincolante,</u>
				può essere però uno strumento utile per capire quali sono le esigenze del tuo progetto. Al termine della
				procedura deciderai se potrò contattarti o meno così da proseguire nella realizzazione.
			</div>

			<div class="text text-center">
				Se hai un
				<strong>codice preventivo,</strong>
				inseriscilo così da ripristinare la configurazione:
			</div>

			<div class="input-group m-auto mt-3 mb-0 codeField">
				<input id="generalCode" type="text" class="form-control" placeholder="Inserisci il codice" />
				<button class="btn btn-outline-secondary m-0" type="button" (click)="importCode()">Importa</button>
			</div>

			<div class="text text-center">altrimenti...</div>

			<div class="buttons">
				<button type="button" class="success" (click)="goToStep({ step: 1 })">Vai al primo step!</button>
			</div>
		</div>
	</div>
</ng-template>

<!--===============================================================
# FINAL STEP TEMPLATE
================================================================-->
<ng-template #finalStepTemplate>
	<div class="options-container recap-container">
		<div class="options-header">
			<h2>Riepilogo preventivo</h2>
		</div>
		<div class="options-header">
			<div class="info-container">
				<div class="info">Ecco il prezzo calcolato in base alle opzioni che hai scelto:</div>
				<div class="finalPrice">{{ finalPrice() }}€</div>
				<div class="info">
					Codice preventivo:
					<strong>{{ actualCode }}</strong>
				</div>
			</div>
		</div>
		<div class="options-recap">
			<ng-container *ngFor="let step of steps">
				<div class="option-recap" *ngIf="getSelectedOptions(step)?.length > 0" (click)="goToStep(step)">
					<h3>{{ step?.title }}</h3>
					<ng-container *ngFor="let option of getSelectedOptions(step)">
						<div *ngIf="option?.selected" class="option">
							<img src="assets/icons/check.svg" />
							{{ option?.text }}
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>

		<div class="options-footer">
			<div class="info">
				Facendo click su "Inviami il preventivo", ti contatterò per discutere del progetto! Così potrai decidere
				tranquillamente se affidarmelo o meno 😊
			</div>

			<div class="d-flex">
				<button type="button" class="primary" (click)="inviaPreventivo()">
					<img class="me-2" src="assets/icons/mail-white.svg" alt="Icona email" />
					Inviami il preventivo
				</button>
				<button
					*ngIf="environment.whatsapp.active"
					type="button"
					class="success ms-2"
					(click)="inviaWhatsapp()">
					<img class="me-2" src="assets/icons/whatsapp-white.svg" alt="Icona whatsapp" />
					Scrivimi su Whatsapp
				</button>
			</div>
		</div>
	</div>
</ng-template>

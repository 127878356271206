import { CommonModule } from '@angular/common';
import { Component, HostListener, NgModule, OnDestroy, OnInit } from '@angular/core';
import { BoxSwiperModule } from '@components/box-swiper/box-swiper.component';
import { BoxModule } from '@components/box/box.component';
import { HeaderModule } from '@components/header/header.component';
import { MeModule } from '@components/me/me.component';
import { SectionBlogModule } from '@components/section-blog/section-blog.component';
import { SectionServiziModule } from '@components/section-servizi/section-servizi.component';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { AnimationsService } from '@services/animations.service';
import { WhatsappService } from '@services/whatsapp.service';
import { DirectivesModule } from '@shared/directives/directives.module';
import { ApiService } from '@shared/services/api.service';
import { RouterService } from '@shared/services/router.service';
import { SidebarService } from '@shared/services/sidebar.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
	stats: any = [];
	technologies: any = [];
	typologies: any = [];

	constructor(
		public routerService: RouterService,
		private sidebarService: SidebarService,
		private apiService: ApiService,
		private whatsappService: WhatsappService,
		private animationService: AnimationsService
	) {}

	async ngOnInit() {
		await this.loadPage();
		this.animationService.start();
	}

	ngOnDestroy(): void {
		this.animationService.stop();
	}

	async loadPage() {
		// Set SEO
		this.routerService.setSEO({
			description: `Sono uno sviluppatore che realizza Siti, Web App e App professionali con l'aiuto di strumenti tecnologici come Angular, Ionic e Laravel`,
			type: 'article',
		});

		// Laoad technologies
		await this.loadInfo();
	}

	async openBoxAction(action: any) {
		const event = action?.event;
		const id = action?.id;

		switch (id) {
			case 'portfolio-web-site':
				this.routerService.open('portfolio-web', event);
				break;

			case 'portfolio-web-app':
				this.routerService.open('portfolio-software', event);
				break;

			case 'portfolio-plugins':
				this.routerService.open('portfolio-app-plugins', event);
				break;

			case 'contatti-idea':
				this.routerService.open('contatti/idea', event);
				break;

			case 'contatti-collaborazione':
				this.routerService.open('contatti/collaborazione', event);
				break;

			case 'contatti-info':
				this.routerService.open('contatti/info', event);
				break;

			case 'whatsapp':
				this.whatsappService.open();
				break;

			case 'blog':
				this.routerService.open('blog', event);
				break;

			default:
				this.infoTechnology(id);
				break;
		}
	}

	/*================================================================
	# LOAD PAGE INFO
	================================================================*/
	async loadInfo() {
		const home: any = await this.apiService.get('home');
		home.technologies.forEach((tech: any) => {
			this.technologies.push({
				title: tech.name,
				preview: {
					image: tech.icon,
				},
				detail: {
					text: tech.description,
					buttons: [{ id: tech.name, text: 'Vedi dettagli' }],
				},
				info: tech,
			});
		});

		this.stats = [
			{
				id: 'projects',
				value: home.stats.projects,
				label: '📊 progetti completati',
			},
			{
				id: 'hours',
				value: home.stats.hours,
				label: '💻 ore di sviluppo',
			},
			{
				id: 'study',
				value: home.stats.studing,
				label: '📚 ore di studio',
			},
			{
				id: 'coffee',
				value: home.stats.coffees,
				label: '☕️ caffè bevuti*',
			},
		];

		this.typologies = home.typologies;
	}

	/*================================================================
	# INFO TECHNOLOGY
	================================================================*/
	async infoTechnology(tech: string = '') {
		if (!tech) return;
		const el = this.technologies.find((x: any) => x.title === tech);
		if (!el) return;

		const elements: any = [
			{
				title: 'Dettagli sui progetti',
				preview: {
					text: `Ho iniziato ad utilizzarlo nel <strong>${
						el.info.startDate
					}</strong> e sino ad ora ci ho sviluppato <strong>${
						el.info.projects || 'diversi'
					} progetti</strong> che potete trovare nel portfolio.`,
					buttons: [
						{
							text: 'Visita il portfolio',
							id: 'portfolio-web-site',
						},
					],
				},
			},
		];

		el.info?.elements?.forEach((element: any) => {
			elements.push({
				title: element.title,
				preview: {
					text: element.text,
				},
			});
		});

		const sidebar = await this.sidebarService.open('info', {
			title: el.info.name,
			data: {
				image: 'assets/img/manutenzione.svg',
				elements,
			},
		});

		if (sidebar) this.openBoxAction(sidebar);
	}

	/*================================================================
	# INFO NUMBERS
	================================================================*/
	async infoNumbers(id: string = '') {
		const el: any = {};

		switch (id) {
			case 'projects':
				const stats = {
					web: this.typologies.find((x: any) => x.type === 'web')?.projects || 0,
					software: this.typologies.find((x: any) => x.type === 'software')?.projects || 0,
					plugin: this.typologies.find((x: any) => x.type === 'plugin')?.projects || 0,
				};

				el.name = 'Progetti';
				el.elements = [
					{
						preview: {
							text: `Queste sono le statistiche relative ai progetti attualmente pubblicati su questo sito.<br><br>Siti web: <strong>${stats.web}</strong><br>Web app e software: <strong>${stats.software}</strong><br>Plugin e implementazioni: <strong>${stats.plugin}</strong>`,
							buttons: [{ id: 'portfolio-web-site', text: 'Vai al portfolio' }],
						},
					},
					{
						title: 'Fasi dello sviluppo',
						preview: {
							text: 'Ogni applicazione ha un meccanismo completamente unico e ottimizzato a seconda delle esigenze.<br><br>Prima del rilascio, sono previste diverse fasi: <br>- Analisi<br>- Progettazione<br>- Sviluppo<br>- Testing<br>- Pubblicazione',
						},
					},
					{
						title: 'Implementazioni successive',
						preview: {
							text: 'Ogni volta che viene richiesta una nuova funzionalità, si ripete il processo di sviluppo. Sembra una cosa lunga, ma in realtà avviene tutto in maniera piuttosto rapida.<br><br>Ovviamente, da quando utilizzo questa metodologia, i bug e i malfunzionamenti sono calati drasticamente.',
						},
					},
					{
						title: 'Hai un progetto da propormi?',
						preview: {
							text: 'Se hai qualche idea da propormi, contattami! Sono sempre felice di aiutare qualcuno a rendere concrete le proprie idee...',
							buttons: [
								{
									id: 'contatti-idea',
									text: `Proponimi un'idea`,
								},
								{
									id: 'whatsapp',
									text: 'Scrivimi su WhatsApp <img class="ms-2" src="/assets/icons/whatsapp-white.svg" />',
									class: 'success',
									hide: !environment.whatsapp.active,
								},
							],
						},
					},
				];
				break;

			case 'hours':
				el.name = 'Ore di sviluppo';
				el.elements = [
					{
						preview: {
							text: `Questo conteggio nasce dal fatto che mi dedico alla programmazione circa <strong>8/10 ore</strong> al giorno.`,
						},
					},
					{
						title: `L'esperienza dai fallimenti`,
						preview: {
							text: `Ricordo ancora all'inizio, quando rimanevo a fissare uno schermo nero con piccole scritte verdi, tanto di quello che c'era scritto era per me completamente incomprensibile. Ho dovuto provare e sbagliare molto prima di iniziare a capire come funzionassero le cose.`,
						},
					},
					{
						title: 'Ho imparato giocando',
						preview: {
							text: 'Mi sono reso conto che, tante delle più grandi complessità e problematiche da risolvere, le ho riscontrate mentre provato a progettare e a sviluppare videogiochi. Il fatto di dover creare azioni che richiedono tantissima interazione, ci fa imbattere in tantissime situazioni al limite.',
						},
					},
					{
						title: 'Vogliamo collaborare?',
						preview: {
							text: 'Come avrai capito mi piace sperimentare. Mi piace esplorare progetti nuovi ed interessanti, se stai cercando uno sviluppatore, contattami!',
							buttons: [
								{
									id: 'contatti-collaborazione',
									text: `Proponimi una collaborazione`,
								},
								{
									id: 'whatsapp',
									text: 'Scrivimi su WhatsApp <img class="ms-2" src="/assets/icons/whatsapp-white.svg" />',
									class: 'success',
									hide: !environment.whatsapp.active,
								},
							],
						},
					},
				];
				break;

			case 'study':
				el.name = 'Studiare è fondamentale';
				el.elements = [
					{
						preview: {
							text: `Ogni giorno cerco sempre di ritagliare un paio di ore per imparare qualcosa di nuovo.`,
						},
					},
					{
						title: `Una buona base`,
						preview: {
							text: `Non smetterò mai di ripetere quanto un buon percorso accademico sia fondamentale. In questi anni ho lavorato con molti programmatori, ti accorgi subito quando ti imbatti in chi fa questo lavoro solo per soldi.<br><br>Studiare gli algoritmi, il clean code, le best practices fanno aumentare tantissimo la qualità del proprio lavoro.`,
						},
					},
					{
						title: 'A volte non pagano...',
						preview: {
							text: `Mi capitava, soprattutto all'inizio. Certo erano tempi diversi, non avevo un mutuo e una famiglia da mantenere, però era il modo migliore per sviluppare.<br><br>Arrivava un problema e, per il solo gusto della sfida, lo affrontavi e lo risolvevi con le soluzioni più creative possibili!`,
						},
					},
					{
						title: 'Non arrendersi al primo errore',
						preview: {
							text: `Ci sono volte in cui qualcosa non riesce al primo colpo. Mi capita soprattutto quando metto le mani su un progetto sviluppato da altri. Se non mi è subito chiara la logica con cui è stato sviluppata una funzionalità, ci vuole anche qualche ora per capirla.<br><br>Ho imparato che è inutile incaponirsi per ore di fronte al problema, meglio prendersi una pausa, al ritorno, è sufficiente smontare il problema in problemi più piccoli e risolverli uno alla volta (dividi et impera).`,
						},
					},
					{
						title: 'Hai qualche domanda?',
						preview: {
							text: 'Se il mio modo di ragionare ti ha colpito, prova a contattarmi, ti risponderò il prima possibile. Mi fa sempre piacere ascoltare nuovi pareri e vedere le cose da un punto di vista differente!',
							buttons: [
								{
									id: 'contatti-info',
									text: `Contatti`,
								},
								{
									id: 'whatsapp',
									text: 'Scrivimi su WhatsApp <img class="ms-2" src="/assets/icons/whatsapp-white.svg" />',
									class: 'success',
									hide: !environment.whatsapp.active,
								},
							],
						},
					},
				];
				break;

			case 'coffee':
				el.name = 'Caffè bevuti';
				el.elements = [
					{
						preview: {
							text: `Il sapore mi piace, è innegabile... però dona quel pizzico di euforia che aiuta a rimanere concentrati.`,
						},
					},
					{
						title: `Mantenere la concentrazione`,
						preview: {
							text: `È una delle cose che mi alleno a mantenere mentre sto lavorando. Se rispondo a telefono o mi arriva un messaggio, rischio di perdere il filo dei pensieri, per quanto possa sembrare banale, dopo non è così semplice capire per quale motivo stavo sviluppando quella determinata cosa in quel determinato modo.`,
						},
					},
					{
						title: `Ogni cosa a suo tempo`,
						preview: {
							text: `Nonostante i molti anni di lavoro, non mi è mai capitato di lavorare la notte. Fortunatamente ho sempre calcolato correttamente i tempi e le scadenza.<br><br>Quelle rare volte in cui mi è capitato di far tardi (le 2 di notte, non di più...), la mattina successiva mi ritrovavo a dover ricontrollare tutto il lavoro fatto.`,
						},
					},
					{
						title: `Questo è il mio modo di lavorare`,
						preview: {
							text: `Probabilmente mi considererai un po' poco convenzionale, ma questo dopotutto è il modo in cui ho imparato a lavorare in questi anni.<br><br>Se ti ha incuriosito, dai un'occhiata al mio blog, ogni tanto provo anche a scrivere qualcosa di interessante! 😛`,
							buttons: [
								{
									text: 'Vai al blog',
									id: 'blog',
								},
							],
						},
					},
				];
				break;
		}

		if (el) {
			const sidebar = await this.sidebarService.open('info', {
				title: el.name,
				data: {
					elements: el?.elements,
				},
			});
			if (sidebar) this.openBoxAction(sidebar);
		}
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [HomeComponent],
	imports: [
		CommonModule,
		TranslateModule,
		HeaderModule,
		DirectivesModule,
		MeModule,
		BoxModule,
		BoxSwiperModule,
		SectionServiziModule,
		SectionBlogModule,
	],
})
export class HomeModule {}

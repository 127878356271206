<div class="page-container">
	<section class="hero">
		<div class="wrapper flex">
			<app-me></app-me>
			<app-section-blog></app-section-blog>
		</div>
		<a href="#servizi" class="mouse-down" aria-label="Scorri verso il basso"></a>

		<!--===============================================================
		# ELEMENTS
		================================================================-->
		<div class="elements">
			<div class="wrapper">
				<div class="element arrow animate fadeInDown">
					<img src="assets/img/mid-arrow.svg" height="180" width="50" alt="arrow-to-down" />
				</div>
			</div>
		</div>
	</section>

	<!--===============================================================
	# SERVIZI
	================================================================-->
	<section class="services" id="servizi">
		<div class="wrapper">
			<h2>Ecco alcuni dei servizi che offro</h2>
			<app-section-servizi></app-section-servizi>
			<button (click)="routerService.open('servizi')">Mostra tutti i servizi</button>
		</div>

		<!--===============================================================
		# ELEMENTS
		================================================================-->
		<div class="elements">
			<div class="wrapper">
				<div class="element logo animate fadeIn">
					<img src="assets/img/logo-white.svg" height="600" width="600" alt="Logo arkas" />
				</div>
			</div>
		</div>
	</section>

	<!--===============================================================
	# STATS
	================================================================-->
	<section class="stats" id="stats">
		<div class="wrapper">
			<h2>Siete amanti dei numeri?</h2>

			<div class="numbers">
				<div class="number" *ngFor="let stat of stats || []" (click)="infoNumbers(stat?.id)">
					<strong>{{ stat?.value }}</strong>
					{{ stat?.label }}
				</div>
			</div>

			<div class="small text-center mt-4">
				* Sto cercando di ridurre la caffeina, ma ho una tazza troppo bella...
			</div>
		</div>

		<!--===============================================================
		# ELEMENTS
		================================================================-->
		<div class="elements">
			<div class="wrapper">
				<div class="element code animate fadeInLeft">
					<img src="assets/icons/code-white.svg" height="200" width="200" alt="code-symbol" />
				</div>
			</div>
		</div>
	</section>

	<!--===============================================================
	# TECHNOLOGIES
	================================================================-->
	<section class="technologies" id="tecnologie">
		<div class="diagonal-top"></div>
		<div class="diagonal-bottom"></div>
		<div class="wrapper">
			<h2>Le tecnologie che utilizzo più spesso</h2>
			<div class="boxes">
				<div class="box" *ngFor="let tech of technologies">
					<app-box (buttonClick)="openBoxAction($event)" [height]="'360px'" [element]="tech"></app-box>
				</div>
			</div>
		</div>

		<!--===============================================================
		# ELEMENTS
		================================================================-->
		<div class="elements">
			<div class="wrapper">
				<div class="element element-x animate fadeInRight">
					<img src="assets/img/element-x.svg" height="200" width="200" alt="element-x" />
				</div>
			</div>
		</div>
	</section>
</div>

<div class="toggle-switch-container">
	<div class="toggle-switch">
		<input
			(change)="change($event)"
			class="toggle-input"
			[value]="value"
			[checked]="checked"
			[id]="id"
			type="checkbox" />
		<label class="toggle-label" [for]="id"></label>
	</div>
	<label class="toggle-label-content" [for]="id"><ng-content></ng-content></label>
</div>

import { Injectable, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject } from '@angular/core';

declare var gtag: Function;

@Injectable({
	providedIn: 'root',
})
export class GoogleAnalyticsService {
	isBrowser: boolean;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	init() {
		if (!environment.googleAnalytics.active || !this.isBrowser) return;
		this.onRouteChange();

		// dynamically add analytics scripts to document head
		try {
			const url = 'https://www.googletagmanager.com/gtag/js?id=';
			const gTagScript = this.document.createElement('script');
			gTagScript.async = true;
			gTagScript.src = `${url}${environment.googleAnalytics.key}`;
			this.document.head.appendChild(gTagScript);

			const dataLayerScript = this.document.createElement('script');
			dataLayerScript.innerHTML = `
			  window.dataLayer = window.dataLayer || [];
			  function gtag(){dataLayer.push(arguments);}
			  gtag('js', new Date());
			  gtag('config', '${environment.googleAnalytics.key}', {'send_page_view': false});`;
			this.document.head.appendChild(dataLayerScript);
		} catch (e) {
			console.error('Error adding Google Analytics', e);
		}
	}

	// track visited routes
	private onRouteChange() {
		if (!environment.googleAnalytics.active) return;
		if (this.route.snapshot.queryParamMap.get('preview') !== null) return;

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd && event.url !== '/#') {
				(window as any).gtag('config', environment.googleAnalytics.key, {
					page_path: event.urlAfterRedirects,
				});
			}
		});
	}

	// Collect event
	public event(event: string, category?: string, label?: string, value?: string) {
		if (!environment.googleAnalytics.active) return;
		gtag('event', event, {
			...(category && { event_category: category }),
			...(label && { event_label: label }),
			...(value && { value: value }),
		});
	}
}

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { v4 as uuid } from 'uuid';

@Injectable({
	providedIn: 'root',
})
export class WhatsappService {
	phoneNumber: string = environment.whatsapp.number;

	open(text?: string) {
		if (window.innerWidth <= 500) {
			var element = document.createElement('a') as HTMLElement;
			element.setAttribute('href', `whatsapp://send?text=${text || ''}&phone=${this.phoneNumber}`);
			element.setAttribute('style', 'display:none;');
			element.click();
			this.closeWA();
		} else {
			this.openWA(text);
		}
	}

	sendWA() {
		const text = (document.getElementById('wa-text') as any)?.value;
		if (text) {
			window.open(`https://wa.me/${this.phoneNumber}?text=${text || ''}`, '_blank');
			this.closeWA();
		}
	}

	openWA(text?: string) {
		this.closeWA();
		const id = 'wa-' + uuid();
		const waContainer = document.createElement('div');
		waContainer.setAttribute('id', id);
		waContainer.setAttribute('class', 'whatsapp-container');
		document.body.appendChild(waContainer);

		const wa = document.createElement('div');
		wa.setAttribute('class', 'whatsapp-message');
		waContainer.appendChild(wa);

		const waClose = document.createElement('div');
		waClose.setAttribute('class', 'close');
		wa.appendChild(waClose);

		const waCloseImg = document.createElement('img');
		waCloseImg.setAttribute('src', 'assets/icons/close-white.svg');
		waClose.appendChild(waCloseImg);

		const waText = document.createElement('textarea');
		waText.setAttribute('id', 'wa-text');
		waText.setAttribute(
			'placeholder',
			'Ciao! Se vuoi chiedermi informazioni, scrivimi qui. Ti risponderò il prima possibile!'
		);
		if (text) waText.innerHTML = text || '';
		wa.appendChild(waText);

		const waBtn = document.createElement('button');
		waBtn.setAttribute('type', 'button');
		waBtn.innerHTML = 'Invia messaggio via WhatsApp <img src="assets/icons/send.svg" />';
		waBtn.setAttribute('class', 'success w-100');
		wa.appendChild(waBtn);

		waText.focus();

		waClose.addEventListener('click', () => {
			this.closeWA();
		});

		waBtn.addEventListener('click', () => {
			this.sendWA();
		});
	}

	closeWA() {
		const els = document.querySelectorAll('.whatsapp-container');
		Array.from(els).forEach(el => el.remove());
	}
}

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

/*================================================================
# SERVICES
================================================================*/
import { RouterService } from './router.service';
import { ToastService } from './toast.service';
import { TokenService } from '@shared/services/token.service';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	apiPath: string = environment.api.path;
	showError: boolean = environment.log.showError;
	showInfo: boolean = environment.log.showInfo;

	constructor(
		private http: HttpClient,
		private routerService: RouterService,
		private tokenService: TokenService,
		private toastService: ToastService
	) {}

	/*================================================================
	# HEADER
	================================================================*/
	getHeader(headers?: any) {
		let head = { headers: { ...headers } };

		if (!headers) {
			const token = this.tokenService.get('token.token');
			if (!token) return {};
			head = { headers: { ...headers, Authorization: 'Bearer ' + token } };
		}

		return head;
	}

	/*================================================================
	# BLOCK APPLICATION
	================================================================*/
	block() {
		this.toastService.open('Sessione scaduta, si prega di effettuare nuovamente il login', 'error', false);
		this.tokenService.setToken();
		this.routerService.open('login');
	}

	/*================================================================
	# GET
	================================================================*/
	async get(path: string, options?: any, headers?: any, forceSuccess: boolean = false) {
		if (!this.tokenService.checkToken(path)) {
			this.block();
		}

		if (options) {
			let count = 0;
			Object.keys(options).forEach(key => {
				options[key] = key === 'filters' ? encodeURIComponent(JSON.stringify(options[key])) : options[key];
				path += `${(count += 1) === 1 ? '?' : '&'}${key}=${options[key]}`;
			});
		}

		return new Promise((resolve, reject) => {
			this.http.get<any>(this.apiPath + path, this.getHeader(headers)).subscribe({
				next: res => {
					if (this.showInfo) {
						console.log('API: ' + path + ' OK');
					}
					resolve(res);
				},
				error: err => {
					if (forceSuccess) {
						resolve(err);
					} else {
						if (this.showError) {
							console.error('API: ' + path + ' ERR');
						}
						reject(err);
					}
				},
			});
		});
	}

	/*================================================================
	# POST
	================================================================*/
	async post(path: string, body: any = {}, options?: any, headers?: any, forceSuccess: boolean = false) {
		if (!this.tokenService.checkToken(path)) {
			this.block();
		}

		if (options) {
			let count = 0;
			Object.keys(options).forEach(key => {
				options[key] = key === 'filters' ? encodeURIComponent(JSON.stringify(options[key])) : options[key];
				path += `${(count += 1) === 1 ? '?' : '&'}${key}=${options[key]}`;
			});
		}

		return new Promise((resolve, reject) => {
			this.http.post<any>(this.apiPath + path, body, this.getHeader(headers)).subscribe({
				next: res => {
					if (this.showInfo) {
						console.log('API: ' + path + ' OK');
					}
					resolve(res);
				},
				error: err => {
					if (forceSuccess) {
						resolve(err);
					} else {
						if (this.showError) {
							console.error('API: ' + path + ' ERR');
						}
						reject(err);
					}
				},
			});
		});
	}

	/*================================================================
	# PUT
	================================================================*/
	async put(path: string, body: any = {}, options?: any, headers?: any, forceSuccess: boolean = false) {
		if (!this.tokenService.checkToken(path)) {
			this.block();
		}

		if (options) {
			let count = 0;
			Object.keys(options).forEach(key => {
				options[key] = key === 'filters' ? encodeURIComponent(JSON.stringify(options[key])) : options[key];
				path += `${(count += 1) === 1 ? '?' : '&'}${key}=${options[key]}`;
			});
		}

		return new Promise((resolve, reject) => {
			this.http.put<any>(this.apiPath + path, body, this.getHeader(headers)).subscribe({
				next: res => {
					if (this.showInfo) {
						console.log('API: ' + path + ' OK');
					}
					resolve(res);
				},
				error: err => {
					if (forceSuccess) {
						resolve(err);
					} else {
						if (this.showError) {
							console.error('API: ' + path + ' ERR');
						}
						reject(err);
					}
				},
			});
		});
	}

	/*================================================================
	# PATCH
	================================================================*/
	async patch(path: string, body: any = {}, options?: any, headers?: any, forceSuccess: boolean = false) {
		if (!this.tokenService.checkToken(path)) {
			this.block();
		}

		if (options) {
			let count = 0;
			Object.keys(options).forEach(key => {
				options[key] = key === 'filters' ? encodeURIComponent(JSON.stringify(options[key])) : options[key];
				path += `${(count += 1) === 1 ? '?' : '&'}${key}=${options[key]}`;
			});
		}

		return new Promise((resolve, reject) => {
			this.http.patch<any>(this.apiPath + path, body, this.getHeader(headers)).subscribe({
				next: res => {
					if (this.showInfo) {
						console.error('API: ' + path + ' OK');
					}
					resolve(res);
				},
				error: err => {
					if (forceSuccess) {
						resolve(err);
					} else {
						if (this.showError) {
							console.error('API: ' + path + ' ERR');
						}
						reject(err);
					}
				},
			});
		});
	}

	/*================================================================
	# DELETE
	================================================================*/
	async delete(path: string, options?: any, headers?: any, forceSuccess: boolean = false) {
		if (!this.tokenService.checkToken(path)) {
			this.block();
		}

		if (options) {
			let count = 0;
			Object.keys(options).forEach(key => {
				options[key] = key === 'filters' ? encodeURIComponent(JSON.stringify(options[key])) : options[key];
				path += `${(count += 1) === 1 ? '?' : '&'}${key}=${options[key]}`;
			});
		}

		return new Promise((resolve, reject) => {
			this.http.delete<any>(this.apiPath + path, this.getHeader(headers)).subscribe({
				next: res => {
					if (this.showInfo) {
						console.log('API: ' + path + ' OK');
					}
					resolve(res);
				},
				error: err => {
					if (forceSuccess) {
						resolve(err);
					} else {
						if (this.showError) {
							console.error('API: ' + path + ' ERR');
						}
						reject(err);
					}
				},
			});
		});
	}
}

import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, NgModule } from '@angular/core';
import { MenuModule } from '@components/menu/menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterService } from '@shared/services/router.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	scrolled: boolean = false;

	constructor(
		public routerService: RouterService,
		@Inject(DOCUMENT) private document: Document
	) {}

	@HostListener('window:scroll', ['$event'])
	onScroll() {
		const actScroll = Math.round(
			window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
		);
		this.scrolled = actScroll > 0;
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [HeaderComponent],
	imports: [CommonModule, TranslateModule, MenuModule],
	exports: [HeaderComponent],
})
export class HeaderModule {}

import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/*================================================================
# MODULES
================================================================*/
import { TranslateModule } from '@ngx-translate/core';
import { HeaderPageModule } from '@components/header-page/header-page.component';
import { SectionModule } from '@components/section/section.component';
import { SummaryModule } from '@components/summary/summary.component';
import { FormContactsModule } from '@components/form-contacts/form-contacts.component';

/*================================================================
# SERVICES
================================================================*/
import { RouterService } from '@shared/services/router.service';
import { WhatsappService } from '@services/whatsapp.service';
import { environment } from '@env/environment';
import { ApiService } from '@shared/services/api.service';

@Component({
	selector: 'app-contatti',
	templateUrl: './contatti.component.html',
	styleUrls: ['./contatti.component.scss'],
})
export class ContattiComponent implements OnInit {
	page: any;
	background = 'assets/img/background.jpg';
	breadcrumbs: any = [{ name: 'Contatti', path: 'contatti' }];
	sectionId: any = null;
	waSection: any = null;

	constructor(
		private route: ActivatedRoute,
		private routerService: RouterService,
		public whatsappService: WhatsappService,
		private apiService: ApiService
	) {}

	/*================================================================
	# INIT
	================================================================*/
	async ngOnInit() {
		this.sectionId = this.route.snapshot.paramMap.get('sectionID');

		switch (this.sectionId) {
			case 'info':
				this.page = await this.apiService.post('page/page/contatti-richiesta-informazioni');
				if (this.page?.background) this.background = this.page?.background;

				this.breadcrumbs = [
					{ name: 'Contatti', path: 'contatti' },
					{ name: 'Richiesta informazioni', path: 'contatti/info' },
				];
				break;
			case 'idea':
				this.page = await this.apiService.post('page/page/contatti-proponi-unidea');
				if (this.page?.background) this.background = this.page?.background;

				this.breadcrumbs = [
					{ name: 'Contatti', path: 'contatti' },
					{ name: "Proponi un'idea", path: 'contatti/idea' },
				];
				break;
			case 'collaborazione':
				this.page = await this.apiService.post('page/page/contatti-proponi-collaborazione');
				if (this.page?.background) this.background = this.page?.background;

				this.breadcrumbs = [
					{ name: 'Contatti', path: 'contatti' },
					{ name: 'Proponi una collaborazione', path: 'contatti/collaborazione' },
				];
				break;
			case 'problema-tecnico':
				this.page = await this.apiService.post('page/page/contatti-segnala-un-problema-tecnico');
				if (this.page?.background) this.background = this.page?.background;

				this.breadcrumbs = [
					{ name: 'Contatti', path: 'contatti' },
					{ name: 'Segnala un problema tecnico', path: 'contatti/problema-tecnico' },
				];
				break;
			default:
				this.page = await this.apiService.post('page/page/contatti');
				if (this.page?.background) this.background = this.page?.background;
				break;
		}

		// Set SEO
		this.routerService.setSEO({
			title: this.page.name,
			description: this.page.description,
			date: this.page.date,
			type: 'article',
		});

		// Whatsapp section
		if (environment.whatsapp.active) {
			this.waSection = {
				separator: true,
				title: 'Vuoi contattarmi più rapidamente?',
				type: 'rows',
				elements: [
					{
						buttons: [
							{
								action: 'emit',
								actionText: 'whatsapp',
								iconUrl: '/assets/icons/whatsapp-white.svg',
								iconPosition: 'end',
								text: 'Scrivimi su WhatsApp',
								id: 'whatsapp',
								class: 'success d-flex m-auto ps-5 pe-5',
							},
						],
					},
				],
			};
		}
	}

	/*================================================================
	# SELECT SECTION
	================================================================*/
	selectSection(id: string) {
		this.routerService.open('contatti/' + id);
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [ContattiComponent],
	imports: [CommonModule, TranslateModule, HeaderPageModule, SectionModule, SummaryModule, FormContactsModule],
})
export class ContattiModule {}

<div class="page-container page-container-bg" [ngStyle]="{ backgroundImage: 'url(' + background + ')' }">
	<article>
		<!-- SECTIONS -->
		<app-section [section]="section" *ngFor="let section of page?.sections"></app-section>
		<!-- END SECTIONS -->
	</article>

	<!-- SECTION CONTATTAMI -->
	<app-section-contattami></app-section-contattami>
	<!-- END SECTION CONTATTAMI -->
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

/*================================================================
# PIPES
================================================================*/
import { nl2brPipe } from './nl2br.pipe';
import { SafePipe } from './safe.pipe';
import { KeepHtmlPipe } from './keepHtml.pipe';
import { ImgCDNPipe } from './imgCDN.pipe';

@NgModule({
	declarations: [nl2brPipe, SafePipe, KeepHtmlPipe, ImgCDNPipe],
	exports: [nl2brPipe, SafePipe, KeepHtmlPipe, ImgCDNPipe],
	imports: [CommonModule],
})
export class PipesModule {}

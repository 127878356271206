import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

/*================================================================
# DIRECTIVES
================================================================*/
import { AutofocusDirective } from './autofocus.directive';
import { AutolabelDirective } from './autolabel.directive';
import { NavTabDirective } from './navtab.directive';
import { TooltipDirective } from './tooltip.directive';
import { LinkDirective } from './link.directive';

@NgModule({
	declarations: [AutofocusDirective, AutolabelDirective, NavTabDirective, TooltipDirective, LinkDirective],
	exports: [AutofocusDirective, AutolabelDirective, NavTabDirective, TooltipDirective, LinkDirective],
	imports: [CommonModule],
})
export class DirectivesModule {}

<div [class.active]="animation" [class.fadeInUp]="animation" class="info-container">
	<div class="col-sx">
		<div class="avatar-container">
			<a href="chi-sono">
				<img class="avatar" alt="Domm avatar" src="assets/img/domm.jpg" />
			</a>
		</div>

		<h2>Ciao!</h2>
		<p>Mi chiamo Cristian e da più di {{ years }} anni sviluppo siti web, app e software professionali.</p>

		<div class="social-container">
			<a href="https://www.linkedin.com/in/cristian-barbato/">
				<img alt="Logo linkedin" src="assets/icons/linkedin-white.svg" />
			</a>
			<a href="https://github.com/domm-it">
				<img alt="Logo github" src="assets/icons/github-white.svg" />
			</a>
			<div *ngIf="environment.whatsapp.active" class="pointer" (click)="whatsappService.open()">
				<img alt="Whatsapp" src="assets/icons/whatsapp-white.svg" />
			</div>
		</div>

		<div class="link-container" *ngIf="links">
			<a href="#servizi">Servizi offerti</a>
			<a href="#tecnologie">Tecnologie utilizzate</a>
		</div>
	</div>
	<!-- <div class="col-dx" *ngIf="links">
		<a href="#tecnologie" class="sheet code"></a>
	</div> -->
</div>

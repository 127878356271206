import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@shared/pipes/pipes.module';
import { ModalService } from '@shared/services/modal.service';
import { RouterService } from '@shared/services/router.service';
import { register } from 'swiper/element/bundle';
import { v4 as uuid } from 'uuid';
register();

import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Component({
	selector: 'app-swiper',
	templateUrl: './swiper.component.html',
	styleUrls: ['./swiper.component.scss'],
})
export class SwiperComponent implements OnInit {
	@Input() active = false;
	@Input() section: any;

	@Output() eventSlideChange = new EventEmitter();
	@Output() eventSlideClick = new EventEmitter();

	style: any = null;
	swiperID = uuid();

	constructor(
		private routerService: RouterService,
		@Inject(DOCUMENT) private document: Document
	) {}

	/*================================================================
	# INIT
	================================================================*/
	ngOnInit(): void {
		this.style = {
			'--swiper-navigation-color': this.section?.color,
			'--swiper-pagination-color': this.section?.color,
		};

		// Detect swiper events
		setTimeout(() => {
			const swiper = this.document.getElementById(this.swiperID);
			swiper?.addEventListener('slidechange', (event: any) => this.swiperChange(event));
		});
	}

	/*================================================================
	# SWIPER CHANGE
	================================================================*/
	swiperChange(event: any) {
		this.pauseAllVideos();
		this.eventSlideChange.emit(event);
	}

	/*================================================================
	# PAUSE ALL VIDEOS
	================================================================*/
	pauseAllVideos() {
		// Pause video
		const iframes = Array.from(this.document.getElementsByTagName('iframe'));
		iframes.forEach((iframe: any) => {
			iframe?.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
		});
	}

	/*================================================================
	# OPEN CTA
	================================================================*/
	open(url: string) {
		if (url.indexOf('http') !== -1) {
			window.open(url, '_blank', 'noreferrer');
		} else {
			this.routerService.open(url);
		}
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	exports: [SwiperComponent],
	declarations: [SwiperComponent],
	imports: [CommonModule, TranslateModule, PipesModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SwiperJSModule {}

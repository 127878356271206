import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';

/*================================================================
# SERVICES
================================================================*/
import { TranslateService } from '@ngx-translate/core';
import { CookieScriptService } from '@shared/services/cookieScript';
import { GoogleAnalyticsService } from '@shared/services/googleAnalytics.service';
import { LoaderService } from '@shared/services/loader.service';
import { RouterService } from '@shared/services/router.service';
import { filter } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	environment = environment;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private loaderService: LoaderService,
		private routerService: RouterService,
		private cookieScriptService: CookieScriptService,
		private googleAnalyticsService: GoogleAnalyticsService
	) {
		this.translate.setDefaultLang('en');
		this.routerService.setSEO();
		this.cookieScriptService.init();
		this.googleAnalyticsService.init();

		this.router.events
			.pipe(filter((x): x is NavigationEnd => x instanceof NavigationEnd))
			.subscribe((event: any) => {
				this.loaderService.ripple(false);
			});
	}
}

import { Component, NgModule, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { CardModule } from '@components/card/card.component';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@shared/directives/directives.module';
import { HeaderPageModule } from '@components/header-page/header-page.component';
import { LoaderService } from '@shared/services/loader.service';
import { PortfolioRelatedModule } from './components/portfolio-related/portfolio-related.component';

/*================================================================
# SERVICES
================================================================*/
import { PortfolioService } from './services/portfolio.service';
import { RouterService } from '@shared/services/router.service';
import { SectionContattamiModule } from '@components/section-contattami/section-contattami.component';
import { SectionModule } from '@components/section/section.component';
import { SummaryModule } from '@components/summary/summary.component';

/*================================================================
# MODULES
================================================================*/
import { TranslateModule } from '@ngx-translate/core';
import { WhatsappService } from '@services/whatsapp.service';

@Component({
	selector: 'app-portfolio',
	templateUrl: './portfolio.component.html',
	styleUrls: ['./portfolio.component.scss'],
})
export class PortfolioComponent implements OnInit {
	breadcrumb: any;
	actualTech: any = [];
	loading: boolean = true;

	id: any = null;
	page: any = null;
	constructor(
		private route: ActivatedRoute,
		private portfolioService: PortfolioService,
		public routerService: RouterService,
		private loaderService: LoaderService,
		public whatsappService: WhatsappService
	) {}

	/*================================================================
	# INIT
	================================================================*/
	async ngOnInit() {
		this.loading = true;
		await this.loadPage();
		this.loading = false;
	}

	/*================================================================
	# GET TYPE
	================================================================*/
	async loadPage() {
		const id = this.route.snapshot.paramMap.get('id') || null;
		const preview = this.route.snapshot.queryParamMap.get('preview') !== null;

		if (id) {
			// Single page
			this.id = id;

			try {
				this.page = await this.portfolioService.getPortfolio(id, preview);
			} catch (err) {
				this.routerService.open('404');
				return;
			}

			// Technology section
			if (this.page?.technologies?.length > 0) {
				const sectionTech: any = {
					title: 'Tecnologie utilizzate',
					separator: true,
					type: 'cols',
					elements: [],
				};
				this.page.technologies.forEach((tech: any) => {
					sectionTech.elements.push({
						icon: tech.icon,
						title: tech.name,
						text: tech.description,
					});
				});
				this.page.sections.push(sectionTech);
			}

			// Breadcrumb
			this.breadcrumb = [
				{ name: this.page?.type?.name, path: this.page?.type?.path },
				{ name: this.page.name, path: 'portfolio/' + this.page.slug },
			];

			// Set SEO
			this.routerService.setSEO({
				name: this.page.name,
				title: this.page.name,
				description: this.page.description,
				date: this.page.date,
				image: this.routerService.domain() + this.page.image,
				type: 'article',
			});
		} else {
			// Generic page
			const type: any = this.route.snapshot.data['type'] || '';
			this.page = await this.portfolioService.getTypologies(type, this.actualTech || null);
			if (this.page) this.breadcrumb = [{ name: this.page?.name, path: this.page?.path }];

			// Set SEO
			this.routerService.setSEO({
				name: this.page.name,
				title: this.page.name,
				description: this.page.description,
				image: this.page.image ? this.routerService.domain() + '/' + this.page.image : null,
				type: 'article',
			});
		}
	}

	/*================================================================
	# FILTER BY TECH
	================================================================*/
	async filterByTech(name: string) {
		if (this.actualTech.includes(name)) {
			this.actualTech = this.actualTech.filter((x: any) => x !== name);
		} else {
			this.actualTech.push(name);
		}
		this.loaderService.show();
		await this.loadPage();
		this.loaderService.hide();
	}

	async resetFilters() {
		this.actualTech = [];
		this.loaderService.show();
		await this.loadPage();
		this.loaderService.hide();
	}

	/*================================================================
	# TRACK BY FN
	================================================================*/
	trackByFn(index: any, item: any): string {
		return item.id;
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [PortfolioComponent],
	imports: [
		CommonModule,
		TranslateModule,
		CardModule,
		DirectivesModule,
		HeaderPageModule,
		SectionModule,
		PortfolioRelatedModule,
		SummaryModule,
		SectionContattamiModule,
	],
})
export class PortfolioModule {}

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	toast: any = null;
	activeToast = false;
	intTimeout: any = null;
	private renderer: Renderer2;

	constructor(rendererFactory: RendererFactory2) {
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	open(message: string = '', type: string | 'error' | 'success' | 'warning' = 'error', autoclose: any = 4000) {
		if (type === 'error') {
			message = '<img class="me-2" src="/assets/icons/warning-danger.svg">' + message;
		}

		if (this.activeToast) {
			// Change actual message and reset autoclose (if exists)
			const activeToast: any = Array.from(document.getElementsByClassName('ark-toast'));
			if (!activeToast.length) {
				return;
			}

			activeToast[0].innerHTML = message;
			this.renderer.setAttribute(activeToast[0], 'class', 'ark-toast ' + type);

			clearInterval(this.intTimeout);
			this.intTimeout = setTimeout(() => {
				this.close();
			}, autoclose);
			return;
		}
		this.activeToast = true;

		const el = Array.from(document.getElementsByClassName('ark-toast'));
		if (el.length > 0) {
			return;
		}

		const toast = this.renderer.createElement('div');
		this.toast = toast;

		toast.innerHTML = message;
		this.renderer.addClass(toast, 'ark-toast');
		this.renderer.addClass(toast, type);
		this.renderer.appendChild(document.body, toast);

		toast?.addEventListener('click', () => {
			this.close();
		});

		if (autoclose !== false) {
			this.intTimeout = setTimeout(() => {
				this.close();
			}, autoclose);
		}
	}

	close() {
		const el = Array.from(document.getElementsByClassName('ark-toast'));
		if (el.length > 0) {
			this.renderer.addClass(this.toast, 'close');
			setTimeout(() => {
				el[0].remove();
			}, 500);
			this.activeToast = false;
		}
	}
}

<div class="wrapper">
	<div class="header" [ngClass]="mode">
		<h1>{{ element?.name }}</h1>

		<div class="header-breadcrumb">
			<app-breadcrumb [mode]="mode" [elements]="breadcrumb"></app-breadcrumb>
		</div>

		<div class="header-bottom" [class.with-image]="showImage && element?.image">
			<div
				class="header-description"
				[ngStyle]="showImage && element?.image ? { backgroundImage: 'url(' + element?.image + ')' } : null">
				<h2 *ngIf="element?.description">{{ element?.description }}</h2>

				<div class="categories-container" *ngIf="element?.categories">
					<div
						(click)="eventCategoryClick.emit(category.id)"
						class="category"
						*ngFor="let category of element?.categories">
						{{ category.name }}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="page-container page-container-bg" [ngStyle]="{ backgroundImage: 'url(' + background + ')' }">
	<!-- HEADER -->
	<app-header-page mode="light" [element]="page" [breadcrumb]="breadcrumbs"></app-header-page>
	<!-- END HEADER -->

	<article>
		<!-- SUMMARY -->
		<app-summary *ngIf="!page?.hideSummary" [page]="page"></app-summary>
		<!-- END SUMMARY -->

		<!-- SECTIONS -->
		<app-section [section]="section" *ngFor="let section of page?.sections"></app-section>
		<!-- END SECTIONS -->
	</article>

	<app-section-contattami></app-section-contattami>
</div>

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { BoxModule } from '@components/box/box.component';
import { TranslateModule } from '@ngx-translate/core';
import { ServiziService } from '@pages/servizi/services/servizi.service';
import { WhatsappService } from '@services/whatsapp.service';
import { RouterService } from '@shared/services/router.service';
import { SidebarService } from '@shared/services/sidebar.service';

@Component({
	selector: 'app-section-servizi',
	templateUrl: './section-servizi.component.html',
	styleUrls: ['./section-servizi.component.scss'],
})
export class SectionServiziComponent implements OnInit {
	@Input() mode: string = 'full';
	services: any = [];

	constructor(
		public routerService: RouterService,
		private serviziService: ServiziService,
		private whatsappService: WhatsappService,
		private sidebarService: SidebarService
	) {}

	ngOnInit(): void {
		this.loadServices();
	}

	loadServices() {
		const services = this.serviziService.list() || [];
		if (this.mode === 'light') {
			this.services = services.slice(0, 3);
		} else {
			this.services = services;
		}
	}

	async openBoxAction(action: any) {
		const event = action?.event;
		const id = action?.id;
		let sidebar = null;
		let info = null;

		switch (id) {
			case 'portfolio-web-site':
				this.routerService.open('portfolio-web', event);
				break;

			case 'portfolio-web-app':
				this.routerService.open('portfolio-software', event);
				break;

			case 'portfolio-plugins':
				this.routerService.open('portfolio-app-plugins', event);
				break;

			case 'contatti-idea':
				this.routerService.open('contatti/idea', event);
				break;

			case 'contatti-collaborazione':
				this.routerService.open('contatti/collaborazione', event);
				break;

			case 'contatti-info':
				this.routerService.open('contatti/info', event);
				break;

			case 'whatsapp':
				this.whatsappService.open();
				break;

			case 'blog':
				this.routerService.open('blog', event);
				break;

			case 'info-web-site':
				info = this.serviziService.get('web-site');
				sidebar = await this.sidebarService.open('info', {
					title: info?.title,
					data: info?.data,
				});
				if (sidebar) this.openBoxAction(sidebar);
				break;

			case 'info-web-app':
				info = this.serviziService.get('web-app');
				sidebar = await this.sidebarService.open('info', {
					title: info?.title,
					data: info?.data,
				});
				if (sidebar) this.openBoxAction(sidebar);
				break;

			case 'info-plugins':
				info = this.serviziService.get('plugins');
				sidebar = await this.sidebarService.open('info', {
					title: info?.title,
					data: info?.data,
				});
				if (sidebar) this.openBoxAction(sidebar);
				break;

			case 'info-app':
				info = this.serviziService.get('app');
				sidebar = await this.sidebarService.open('info', {
					title: 'Informazioni sulle App',
					data: info?.data,
				});
				if (sidebar) this.openBoxAction(sidebar);
				break;

			case 'info-manutenzione':
				info = this.serviziService.get('manutenzione');
				sidebar = await this.sidebarService.open('info', {
					title: info?.title,
					data: info?.data,
				});
				if (sidebar) this.openBoxAction(sidebar);
				break;
		}
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [SectionServiziComponent],
	exports: [SectionServiziComponent],
	imports: [CommonModule, TranslateModule, BoxModule],
})
export class SectionServiziModule {}

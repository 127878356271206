<swiper-container
	[id]="swiperID"
	pagination="true"
	navigation="true"
	[ngStyle]="style"
	[class.colored]="section?.color">
	<swiper-slide [ngClass]="'img-' + slide?.mediaPosition" *ngFor="let slide of section?.elements">
		<div class="swiper-element">
			<div class="wrapper flex">
				<ng-container [ngTemplateOutlet]="slideElement" [ngTemplateOutletContext]="{ slide }"></ng-container>
			</div>
			<div class="swiper-bg" *ngIf="slide?.background">
				<img alt="Background" [src]="slide?.background" />
			</div>
		</div>
	</swiper-slide>
</swiper-container>

<!-- TEMPLATE COLUMN -->
<ng-template #slideElement let-slide="slide">
	<div class="swiper-box">
		<!-- IMAGE -->
		<div class="swiper-image" *ngIf="slide?.mediaUrl" (click)="eventSlideClick.emit(slide)">
			<img [alt]="slide?.mediaDescription || section?.title || 'Arkastech section'" [src]="slide?.mediaUrl" />
			<div class="description" *ngIf="slide?.mediaDescription">{{ slide?.mediaDescription }}</div>
		</div>
		<!-- END IMAGE -->

		<!-- VIDEO -->
		<!-- <div class="swiper-video" [ngClass]="box?.video?.class" *ngIf="box?.video">
			<h3 *ngIf="box?.title">
				<span [class.active]="active" [ngClass]="box?.title?.class">
					{{ box.title.content }}
				</span>
			</h3>
			<iframe
				[src]="'https://www.youtube-nocookie.com/embed/' + box?.video?.content + '?enablejsapi=1' | safe"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen></iframe>
		</div> -->
		<!-- END VIDEO -->

		<!-- TEXT -->
		<div class="swiper-content" *ngIf="slide?.title || slide?.text">
			<h3 *ngIf="slide?.title">{{ slide.title }}</h3>
			<div class="swiper-text" *ngIf="slide?.text">{{ slide.text }}</div>
			<button *ngIf="slide?.cta" [ngClass]="slide?.cta?.class" (click)="open(slide?.cta?.url)">
				{{ slide?.cta?.content }}
			</button>
		</div>
		<!-- END TEXT -->
	</div>
</ng-template>
<!-- END TEMPLATE COLUMN -->

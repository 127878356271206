<div class="page-container page-container-bg" [ngStyle]="{ backgroundImage: 'url(' + background + ')' }">
	<!-- HEADER -->
	<app-header-page mode="light" [element]="page" [breadcrumb]="breadcrumbs"></app-header-page>
	<!-- END HEADER -->

	<!-- SUMMARY -->
	<app-summary *ngIf="!page?.hideSummary" [page]="page"></app-summary>
	<!-- END SUMMARY -->

	<!-- GENERIC -->
	<ng-container *ngIf="!sectionId">
		<ng-container *ngTemplateOutlet="contatti"></ng-container>
	</ng-container>
	<!-- END GENERIC -->

	<ng-container *ngIf="sectionId">
		<ng-container *ngTemplateOutlet="contattiDetail"></ng-container>
	</ng-container>
</div>

<!--===============================================================
# GENERIC PAGE CONTATTI
================================================================-->
<ng-template #contatti>
	<app-section [section]="section" *ngFor="let section of page?.sections"></app-section>
	<section class="wrapper">
		<div class="options">
			<div class="option" (click)="selectSection('info')">
				<img src="assets/icons/info-circle-white.svg" />
				<div class="title">Richiesta informazioni</div>
			</div>
			<div class="option" (click)="selectSection('idea')">
				<img src="assets/icons/bulb-white.svg" />
				<div class="title">Proponi un'idea</div>
			</div>
			<div class="option" (click)="selectSection('collaborazione')">
				<img src="assets/icons/collaboration-white.svg" />
				<div class="title">Proponi collaborazione</div>
			</div>
			<div class="option" (click)="selectSection('problema-tecnico')">
				<img src="assets/icons/wrench-white.svg" />
				<div class="title">Segnala un problema tecnico</div>
			</div>
		</div>
	</section>
</ng-template>

<!--===============================================================
# FORMS PAGE
================================================================-->
<ng-template #contattiDetail>
	<app-section [section]="section" *ngFor="let section of page?.sections"></app-section>

	<section class="wrapper">
		<app-form-contacts [type]="breadcrumbs[1]?.name"></app-form-contacts>
	</section>

	<app-section
		(buttonClicked)="$event === 'whatsapp' ? whatsappService.open() : null"
		[section]="waSection"
		*ngIf="waSection"></app-section>
</ng-template>

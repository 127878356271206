import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { SectionModule } from '@components/section/section.component';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { WhatsappService } from '@services/whatsapp.service';
import { RouterService } from '@shared/services/router.service';

@Component({
	selector: 'app-section-contattami',
	templateUrl: './section-contattami.component.html',
	styleUrls: ['./section-contattami.component.scss'],
})
export class SectionContattamiComponent {
	environment = environment;

	// Contact section
	contactSection = {
		title: 'Ti piace questo progetto?',
		description: 'Se vuoi realizzarne uno simile, contattami!',
		type: 'rows',
		elements: [
			{
				buttons: [
					{
						text: 'Compila il form contatti',
						id: 'contact-info',
						class: 'primary d-flex m-auto px-5',
					},
					{
						text: 'Scrivimi su WhatsApp <img class="ms-2" src="/assets/icons/whatsapp-white.svg" />',
						id: 'whatsapp',
						hide: !environment.whatsapp.active,
						class: 'success d-flex m-auto px-5',
					},
				],
			},
		],
	};

	constructor(
		public whatsappService: WhatsappService,
		public routerService: RouterService
	) {}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [SectionContattamiComponent],
	exports: [SectionContattamiComponent],
	imports: [CommonModule, TranslateModule, SectionModule],
})
export class SectionContattamiModule {}

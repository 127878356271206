import { HostListener, Injectable } from '@angular/core';
import { Alert, AlertButton, initialAlert } from '../models/alert';
import { v4 as uuid } from 'uuid';

@Injectable({
	providedIn: 'root',
})
export class AlertService {
	zIndex = 100;
	elType = 'alert';
	eventRef: any = null;

	constructor() {}

	/*================================================================
	# OPEN MODAL
	================================================================*/
	open(message: string, options?: Alert) {
		// Include default options
		options = { ...initialAlert, ...options };

		return new Promise((resolve, reject) => {
			// Generate modal
			const element = this.generateElement({
				...options,
				id: uuid(),
				message,
			});
			resolve(element);
		});
	}

	/*================================================================
	# CONFIRM
	================================================================*/
	async confirm(message: string) {
		return new Promise(async (resolve, reject) => {
			const res: any = await this.open(message, {
				buttons: [
					{ id: 'cancel', name: 'Annulla', dismiss: true },
					{ id: 'ok', name: 'Ok', dismiss: true },
				],
			});
			resolve(res.role === 'ok');
		});
	}

	/*================================================================
	# GENERATE MODAL
	================================================================*/
	generateElement(obj: any) {
		return new Promise((resolve, reject) => {
			// Create container
			const element: any = document.createElement('div');
			element.setAttribute('id', obj.id);
			element.setAttribute('class', 'ark-' + this.elType + '-container');
			element.style.zIndex = this.zIndex += 1;

			// Create content
			const elementContent = document.createElement('div');
			elementContent.classList.add('ark-' + this.elType);
			element.appendChild(elementContent);

			// Create main
			const elementMain = document.createElement('main');
			let str = obj.message;
			if (obj.title.length > 0) {
				str = `<div class="title">${obj.title}</div>` + str;
			}
			elementMain.innerHTML = str;
			elementContent.appendChild(elementMain);

			// ForceModalClosure
			const forceClosure = document.createElement('div');
			forceClosure.setAttribute('class', 'alertForceClosure');
			forceClosure.setAttribute('data', JSON.stringify({ role: 'dismiss', data: {} }));
			forceClosure.style.display = 'none';
			elementContent.appendChild(forceClosure);
			element?.getElementsByClassName('alertForceClosure')[0].addEventListener('click', () => {
				this.close(element.id);
				const data = forceClosure.getAttribute('data') || JSON.stringify({ role: 'dismiss', data: {} });
				resolve(JSON.parse(data));
			});

			// Create footer
			const elementFooter = document.createElement('footer');
			elementContent.appendChild(elementFooter);

			// Footer buttons
			obj.buttons.forEach((button: AlertButton) => {
				const footerBtn = document.createElement('button');
				footerBtn.setAttribute('type', 'button');
				footerBtn.classList.add('ark-btn');
				footerBtn.innerHTML = button.name;
				elementFooter.appendChild(footerBtn);

				if (button.dismiss) {
					footerBtn?.addEventListener('click', () => {
						this.close(obj.id);
						const role = button.id ? button.id : button.name;
						resolve({ role, data: {} });
					});
				}
			});

			// Insert in body
			document.body.appendChild(element);
			setTimeout(() => this.activeAlert(obj.id));

			// Add listner (keydown)
			this.eventRef = (event: any) => {
				event.preventDefault();
				if (event.key === 'Enter') {
					const data = { role: 'ok', data: {} };
					this.close('', data);
					resolve(obj);
				} else if (event.key === 'Escape') {
					const data = { role: 'dismiss', data: {} };
					this.close('', data);
					resolve(obj);
				}
			};
			window?.addEventListener('keydown', this.eventRef);

			// Create element ovelay
			if (obj.overlay) {
				const elementOverlay = document.createElement('div');
				elementOverlay.setAttribute('class', 'ark-' + this.elType + '-overlay');
				element.appendChild(elementOverlay);

				if (obj.dismissable) {
					element?.getElementsByClassName('ark-alert-overlay')[0].addEventListener('click', () => {
						this.close(obj.id);
						resolve({ role: 'dismiss', data: {} });
					});
				}
			}
		});
	}

	/*================================================================
	# GET MAX ZINDEX
	================================================================*/
	getMaxZIndex() {
		let maxZIndex = 0;
		Array.from(document.getElementsByClassName('ark-modal-container')).forEach((x: any) => {
			const actZ = parseInt(x.style.zIndex, 10) || 100;
			if (actZ > maxZIndex) {
				maxZIndex = actZ;
			}
		});
		Array.from(document.getElementsByClassName('ark-alert-container')).forEach((x: any) => {
			const actZ = parseInt(x.style.zIndex, 10) || 100;
			if (actZ > maxZIndex) {
				maxZIndex = actZ;
			}
		});
		maxZIndex = maxZIndex + 1;
		return maxZIndex + '';
	}

	/*================================================================
	# ACTIVATE ALERT
	================================================================*/
	activeAlert(id: string) {
		const el: any = document.getElementById(id) || '';

		if (el) {
			Array.from(document.getElementsByClassName('ark-alert-container')).forEach(x =>
				x.classList.remove('active')
			);
			el.classList.add('active');
			el.style.zIndex = this.getMaxZIndex();
		}
	}

	/*================================================================
	# CLOSE MODAL
	================================================================*/
	close(id: string = '', data: any = { role: 'dismiss', data: {} }) {
		// REMOVE listner (keydown)
		window?.removeEventListener('keydown', this.eventRef);

		if (!id) {
			const el: any = document.getElementById(
				document.getElementsByClassName('ark-alert-container active')[0].id
			);
			const forceClosure = el?.getElementsByClassName('alertForceClosure')[0];
			forceClosure.setAttribute('data', JSON.stringify(data));
			forceClosure.click();
			return;
		}

		document.getElementById(id)?.classList.add('close');
		setTimeout(() => {
			document.getElementById(id)?.remove();
		}, 500);
	}
}

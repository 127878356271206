<div
	class="page-container page-container-bg"
	[ngStyle]="{ backgroundImage: 'url(' + page?.background + ')' }"
	*ngIf="!loading">
	<!-- HEADER -->
	<app-header-page mode="light" [showImage]="true" [element]="page" [breadcrumb]="breadcrumb"></app-header-page>
	<!-- END HEADER -->

	<!-- BLOG GENERAL -->
	<ng-container *ngIf="!id">
		<ng-container *ngTemplateOutlet="portfolio; context: { page }"></ng-container>
	</ng-container>
	<!-- END BLOG GENERAL -->

	<!-- SINGLE PAGE -->
	<ng-container *ngIf="id">
		<ng-container *ngTemplateOutlet="portfolioSingle; context: { page }"></ng-container>
	</ng-container>
	<!-- END SINGLE PAGE -->

	<!-- SECTION CONTATTAMI -->
	<div class="contattami-container">
		<app-section-contattami></app-section-contattami>
	</div>
	<!-- END SECTION CONTATTAMI -->
</div>

<!--===============================================================
# MAIN PAGE
================================================================-->
<ng-template #portfolio let-page="page">
	<div class="wrapper">
		<!-- SWITCH PORTFOLIO TYPES -->
		<div class="switcher-container">
			<div
				class="switcher-element"
				[class.active]="page?.type === 'web'"
				(click)="routerService.open('portfolio-web')">
				Web
			</div>
			<div
				class="switcher-element"
				[class.active]="page?.type === 'software'"
				(click)="routerService.open('portfolio-software')">
				Software
			</div>
			<div
				class="switcher-element"
				[class.active]="page?.type === 'plugin'"
				(click)="routerService.open('portfolio-app-plugins')">
				App/Plugins
			</div>
		</div>
		<!-- END SWITCH PORTFOLIO TYPES -->

		<!-- TECHNOLOGIES -->
		<div class="technologies" *ngIf="page?.technologies?.length > 0">
			<h3>Tecnologie utilizzate:</h3>
			<div class="logos">
				<ng-container *ngFor="let tech of page?.technologies">
					<img
						(click)="filterByTech(tech?.name)"
						[class.active]="actualTech.includes(tech?.name)"
						[tooltip]="tech?.name"
						[alt]="tech?.name"
						[src]="tech?.icon" />
				</ng-container>
			</div>
		</div>
		<!-- END TECHNOLOGIES -->

		<!-- PORTFOLIO GRID -->
		<div class="portfolio-container">
			<ng-container *ngIf="page?.elements?.length > 0">
				<app-card [element]="el" *ngFor="let el of page?.elements; trackBy: trackByFn"></app-card>
			</ng-container>
			<ng-container *ngIf="page?.elements?.length === 0">
				<div class="no-results">
					<p>Non sono presenti elementi che rispettano i filtri selezionati</p>
					<button type="button" (click)="resetFilters()">Resetta i filtri</button>
				</div>
			</ng-container>
		</div>
		<!-- END PORTFOLIO GRID -->
	</div>
</ng-template>

<!--===============================================================
# DETAIL
================================================================-->
<ng-template #portfolioSingle let-page="page">
	<article>
		<!-- SOMMARIO -->
		<app-summary *ngIf="!page?.hideSummary" [page]="page"></app-summary>
		<!-- END SOMMARIO -->

		<!-- URL SITE -->
		<section class="website-container" *ngIf="page?.url">
			<div class="wrapper">
				<div class="website-content">
					<a [href]="page?.url">Visita il sito web del progetto</a>
				</div>
			</div>
		</section>
		<!-- END URL SITE -->

		<!-- SECTIONS -->
		<app-section [section]="section" *ngFor="let section of page?.sections"></app-section>
		<!-- END SECTIONS -->
	</article>

	<!-- RELATED -->
	<section id="related" class="related-container" *ngIf="page?.related?.length > 0">
		<app-portfolio-related [related]="page?.related"></app-portfolio-related>
	</section>
	<!-- END RELATED -->
</ng-template>

import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';
import { RouterService } from '@shared/services/router.service';

@Pipe({
	name: 'cdn',
})
export class ImgCDNPipe implements PipeTransform {
	constructor(private routerService: RouterService) {}

	transform(url: any, w?: number) {
		if (environment.cdn.image.path) {
			url = environment.cdn.image.path + '/' + url;
			if (w !== undefined) url += '?w=' + w;
		}
		return url;
	}
}

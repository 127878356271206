import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, NgModule } from '@angular/core';
import { BoxModule } from '@components/box/box.component';
import { TranslateModule } from '@ngx-translate/core';
import { register } from 'swiper/element/bundle';

register();

@Component({
	selector: 'app-box-swiper',
	templateUrl: './box-swiper.component.html',
	styleUrls: ['./box-swiper.component.scss'],
})
export class BoxSwiperComponent {
	@Input() elements: any = [];
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [BoxSwiperComponent],
	exports: [BoxSwiperComponent],
	imports: [CommonModule, TranslateModule, BoxModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BoxSwiperModule {}

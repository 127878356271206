import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LoaderService {
	loader: any = null;
	activeLoader = false;
	private renderer: Renderer2;

	constructor(
		rendererFactory: RendererFactory2,
		@Inject(DOCUMENT) private document: Document
	) {
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	/*================================================================
	# SHOW LOADER
	================================================================*/
	show() {
		if (this.activeLoader) {
			return;
		}
		this.activeLoader = true;

		const el = Array.from(this.document.getElementsByClassName('loader'));
		if (el.length > 0) {
			return;
		}

		const loader = this.renderer.createElement('div');
		this.loader = loader;
		this.renderer.appendChild(this.document.body, loader);
		this.renderer.addClass(loader, 'loader');
	}

	/*================================================================
	# HIDE LOADER
	================================================================*/
	hide() {
		const el = Array.from(this.document.getElementsByClassName('loader'));
		if (el.length > 0) {
			this.renderer.addClass(this.loader, 'close');
			setTimeout(() => {
				el[0].remove();
				this.activeLoader = false;
			}, 500);
		}
	}

	/*================================================================
	# SHOW PROGRESS
	================================================================*/
	progress(value: number = 0) {
		if (this.activeLoader) {
			// Change actual message and reset autoclose (if exists)
			const activeProgress: any = Array.from(this.document.getElementsByClassName('progress-bar'));
			this.renderer.setStyle(activeProgress[0], 'width', value + '%');

			const activeProgressInfo: any = Array.from(this.document.getElementsByClassName('progress-bar-info'));
			this.renderer.setProperty(activeProgressInfo[0], 'textContent', value + '%');

			// Hide progress on complete
			if (value >= 100) {
				this.renderer.setProperty(activeProgressInfo[0], 'textContent', 'Completato');
				this.hide();
			}
			return;
		}

		this.activeLoader = true;

		const el = Array.from(this.document.getElementsByClassName('loader'));
		if (el.length > 0) {
			return;
		}

		// Create container
		const loader = this.renderer.createElement('div');
		this.loader = loader;
		this.renderer.appendChild(this.document.body, loader);
		this.renderer.addClass(loader, 'loader');
		this.renderer.addClass(loader, 'progress');

		// Create progress container
		const progressContainer = this.renderer.createElement('div');
		this.renderer.addClass(progressContainer, 'progress-bar-container');
		this.renderer.appendChild(loader, progressContainer);

		// Create progress bar
		const progressBar = this.renderer.createElement('div');
		this.renderer.addClass(progressBar, 'progress-bar');
		this.renderer.appendChild(progressContainer, progressBar);
		this.renderer.setStyle(progressBar, 'width', value + '%');

		// Create info text
		const infoText = this.renderer.createElement('div');
		this.renderer.addClass(infoText, 'progress-bar-info');
		this.renderer.appendChild(progressContainer, infoText);
		this.renderer.setProperty(infoText, 'textContent', value + '%');
	}

	/*================================================================
	# MOUSE RIPPLE
	================================================================*/
	ripple(show: boolean = true, event: any = false) {
		if (show) {
			const loader = this.renderer.createElement('div');
			this.loader = loader;
			this.renderer.appendChild(this.document.body, loader);
			this.renderer.addClass(loader, 'loader-ripple');

			if (event) {
				if (!event.clientX) event = window.event;
				this.rippleEffect(event);
			} else {
				window.addEventListener('click', this.rippleEffect);
				setTimeout(() => window.removeEventListener('click', this.rippleEffect));
			}
		} else {
			const el = Array.from(this.document.getElementsByClassName('loader-ripple'));
			if (el.length > 0) {
				this.document.body.style.overflow = 'auto';
				if (!this.loader.classList.contains('close')) {
					this.renderer.addClass(this.loader, 'close');
					setTimeout(() => el[0].remove(), 1000);
				}
				return;
			}
		}
	}

	rippleEffect(e: any) {
		const el = Array.from(this.document.getElementsByClassName('loader-ripple'));
		if (el.length) {
			this.document.body.style.overflow = 'hidden';
			(el[0] as any).style.left = `${e.clientX}px`;
			(el[0] as any).style.top = `${e.clientY}px`;
		}
	}
}

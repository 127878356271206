import { HostListener, Injectable, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class AnimationsService {
	constructor(@Inject(DOCUMENT) private document: Document) {}

	start() {
		this.animate();
		this.document.addEventListener('scroll', this.animate);
	}

	stop() {
		this.document.removeEventListener('scroll', this.animate);
	}

	animate() {
		const elements = document.getElementsByClassName('animate');
		const scrollTop = (document.documentElement.scrollTop || document.body.scrollTop) + window.outerHeight / 3;

		let counter = 1;
		Array.from(elements)
			.filter(x => !x.classList.contains('active'))
			.forEach((el: any) => {
				if (!el.classList?.value?.includes('active')) {
					if (el.getBoundingClientRect()?.top < scrollTop) {
						setTimeout(() => el.classList.add('active'), 100 * counter++);
					}
				}
			});
	}
}

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

/*================================================================
# SERVICES
================================================================*/
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root',
})
export class MailService {
	apiPath: string = environment.api.path;
	showError: boolean = environment.log.showError;
	showInfo: boolean = environment.log.showInfo;

	constructor(private apiService: ApiService) {}

	async send(body: MessageOptions) {
		return new Promise(async (resolve, reject) => {
			try {
				await this.apiService.post(environment.mail.api.path, body);
				resolve(true);
			} catch (err) {
				resolve(false);
			}
		});
	}
}

export interface MessageOptions {
	message: string;
	subject: string;
}

import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { HeaderPageModule } from '@components/header-page/header-page.component';

/*================================================================
# MODULES
================================================================*/
import { TranslateModule } from '@ngx-translate/core';
import { SectionModule } from '@components/section/section.component';
import { SummaryModule } from '@components/summary/summary.component';
import { RouterService } from '@shared/services/router.service';
import { WhatsappService } from '@services/whatsapp.service';
import { SectionContattamiModule } from '@components/section-contattami/section-contattami.component';
import { ApiService } from '@shared/services/api.service';

@Component({
	selector: 'app-chi-sono',
	templateUrl: './chi-sono.component.html',
	styleUrls: ['./chi-sono.component.scss'],
})
export class ChiSonoComponent implements OnInit {
	page: any;
	background = 'assets/img/background.jpg';
	breadcrumbs: any = [{ name: 'Chi sono', path: 'chi-sono' }];

	constructor(
		public routerService: RouterService,
		public whatsappService: WhatsappService,
		private apiService: ApiService
	) {}

	/*================================================================
	# INIT
	================================================================*/
	async ngOnInit() {
		this.page = await this.apiService.post('page/page/chi-sono');
		if (this.page?.background) this.background = this.page?.background;

		// Set SEO
		this.routerService.setSEO({
			title: this.page.name,
			description: this.page.description,
			date: this.page.date,
			type: 'article',
		});
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [ChiSonoComponent],
	imports: [CommonModule, TranslateModule, HeaderPageModule, SectionModule, SummaryModule, SectionContattamiModule],
})
export class ChiSonoModule {}

import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { WhatsappService } from '@services/whatsapp.service';
import { AlertService } from '@shared/services/alert.service';
import { RouterService } from '@shared/services/router.service';
import { v4 as uuid } from 'uuid';

@Component({
	selector: 'app-preventivo',
	templateUrl: './preventivo.component.html',
	styleUrls: ['./preventivo.component.scss'],
})
export class PreventivoComponent {
	actualStep: number = 0;
	actualCode: string = '';
	lastStepVisited: number = 0;
	environment = environment;
	componentID = 'comp-' + uuid();

	steps: any = [
		{
			step: 1,
			title: 'Che tipo di sito avevi in mente?',
			multi: false,
			required: true,
			options: [
				{
					text: 'Creazione',
					description: `Hai un'idea in mente e vuoi realizzarla insieme a me?`,
					price: 200,
				},
				{
					text: 'Aggiornamento',
					description: `Vuoi che il tuo sito venga ottimizzato e aggiornato?`,
					price: 300,
				},
				{
					text: 'Restyling',
					description: `Ti piacciono i vecchi contenuti ma vuoi qualcosa di fresco?`,
					price: 400,
				},
			],
		},
		{
			step: 2,
			title: 'Scegli il tipo di sito che vuoi realizzare',
			multi: false,
			required: true,
			options: [
				{
					text: 'Singola pagina',
					description: `Il visitatore non dovrà far altro che scrollare verso il basso per ottenere tutte le info!`,
					price: 300,
				},
				{
					text: 'Sito standard',
					description: `Un sito suddiviso in sezioni e sottosezioni navigabili tramite link e bottoni`,
					price: 500,
				},
				{
					text: 'E-commerce',
					description: `Un sito web interattivo che ti consentirà di vendere i tuoi prodotti direttamente online`,
					price: 750,
				},
			],
		},
		{
			step: 3,
			title: 'Quanto pensi sia grande il tuo sito?',
			multi: false,
			required: true,
			options: [
				{
					text: 'Piccolo',
					description: 'Fino a 3 pagine (o 50 prodotti)',
					price: 100,
				},
				{
					text: 'Medio',
					description: 'Fino a 10 pagine (o 200 prodotti)',
					price: 500,
				},
				{
					text: 'Grande',
					description: 'Più di 10 pagine (o 200 prodotti)',
					price: 1500,
				},
			],
		},
		{
			step: 4,
			title: 'Vuoi aggiungere qualche altra funzionalità?',
			multi: true,
			required: false,
			options: [
				{
					text: 'Modulo contatti',
					description: `I visitatori possono inviarti delle email direttamente dal sito`,
					price: 100,
				},
				{
					text: 'Area riservata',
					description: `I tuoi clienti possono registrarsi e accedere alla loro area  sul tuo sito`,
					price: 200,
				},
				{
					text: 'SEO PRO',
					description: `Un'ottimizzazione mirata ad incrementare la visibilità di ogni pagina`,
					price: 300,
				},
				{
					text: 'Multilingua',
					description: `Questo sito non sarà solamente in italiano ma ci saranno altre lingue`,
					price: 100,
				},
				{
					text: 'Pagamenti',
					description: 'I tuoi clienti potranno pagare tramite paypal o stripe dal sito',
					price: 150,
				},
				{
					text: 'Integrazione',
					description: 'Vuoi inviare o ricevere dati da altre piattaforme?',
					price: 500,
				},
				{
					text: 'Daily Backup',
					description: `Sarà possibile ripristinare il sito dalla mezzanote del giorno precedente`,
					price: 200,
				},
			],
		},
		{
			step: 5,
			title: 'Altri servizi',
			multi: true,
			required: false,
			options: [
				{
					text: 'Creazione del logo',
					description: `Vuoi farmi realizzare anche il tuo logo professionale?`,
					price: 200,
				},
				{
					text: 'Servizio fotografico',
					description: 'Realizzeremo 15 foto professionali da usare sul tuo nuovo sito',
					price: 300,
				},
				{
					text: 'Creazione pagina social',
					description: 'Vuoi che mi occupi anche della creazione di una pagina social?',
					price: 150,
				},
			],
		},
	];

	constructor(
		private alertService: AlertService,
		private routerService: RouterService,
		private whatsappService: WhatsappService,
		@Inject(DOCUMENT) private document: Document
	) {}

	selectOption(step: any, option: any) {
		if (step?.multi) {
			option.selected = !option.selected;
		} else {
			const isSelected = step.options.find((x: any) => x.text === option.text && option.selected);
			const actualSelected = step.options.filter((x: any) => x.selected && x.text !== option.text);
			if (actualSelected.length > 0) step.options.forEach((el: any) => delete el.selected);
			option.selected = !isSelected;
		}
	}

	goToStep(step: any, action?: string) {
		if (!this.checkAllFilled(step?.step || step)) {
			this.alertService.open(
				`Prima di procedere oltre, devi selezionare almeno un'opzione in questo step. Poi potrai proseguire...`,
				{
					title: 'Non ancora 😛',
				}
			);
			return;
		}
		const actStep = this.steps.find((x: any) => x.step === this.actualStep);

		if (step === 'final') {
			if (!this.isSelectable(actStep)) return;
			this.actualStep = this.steps.length + 1;
			this.actualCode = this.exportCode();
		} else if (step === 'initial') {
			this.actualStep = 0;
		} else {
			if (!action) {
				this.actualStep = step?.step;
			} else if (action === 'prev') {
				this.actualStep = step?.step - 1;
			} else if (action === 'next') {
				this.actualStep = step?.step + 1;
				if (this.actualStep === this.steps.length) this.actualCode = this.exportCode();
			}
		}

		// Scroll to the top of preventivo
		const el: any = this.document.getElementById(this.componentID);
		el.scrollIntoView({ behavior: 'smooth' }, true);
	}

	checkAllFilled(target?: any) {
		if (!target) {
			let check = 0;
			let required = this.steps.filter((step: any) => step.required);
			required.forEach((step: any) => {
				if (this.isCompleted(step)) check += 1;
			});
			return required.length === check;
		} else {
			target = target === 'final' ? this.steps.length : target === 'initial' ? 0 : target;
			let checked = 1;
			for (let i = 0; i < target; i++) {
				const step = this.steps[i];
				if (!step?.required || this.isCompleted(step)) {
					checked += 1;
				}
			}
			return target <= checked;
		}
	}

	isFilled(step: any) {
		return step?.options?.filter((x: any) => x.selected)?.length > 0;
	}

	isCompletedBefore(actStep: any) {
		let completed = 0;
		for (let i = 0; i < actStep?.step; i++) {
			const step = this.steps[i];
			if (this.isCompleted(step) && this.actualStep >= actStep?.step) completed += 1;
		}
		return actStep?.step === completed;
	}

	isCompleted(step?: any, alsoFilled: boolean = false) {
		if (!step) {
			let completed = 0;
			this.steps.forEach((actStep: any) => {
				if (this.isCompleted(actStep)) completed += 1;
			});
			return this.steps.length === completed;
		} else {
			return step?.required ? this.isFilled(step) : alsoFilled;
		}
	}

	isSelectable(step: any, action?: string): any {
		if (!action) {
			return step?.required ? this.isCompleted(step) : true;
		} else if (action === 'prev') {
			const prevStep = this.steps.find((x: any) => x?.step === step?.step - 1);
			return !!prevStep && this.isSelectable(step);
		} else if (action === 'next') {
			const nextStep = this.steps.find((x: any) => x?.step === step?.step + 1);
			this.lastStepVisited = nextStep?.step || 0;

			const lastStep = step?.step === this.steps.length;
			return (!!nextStep || lastStep) && this.isSelectable(step);
		}
	}

	finalPrice() {
		let price = 0;
		this.steps.forEach((step: any) => {
			step?.options
				?.filter((option: any) => option?.selected)
				.forEach((option: any) => {
					price += option?.price || 0;
				});
		});
		return price;
	}

	getSelectedOptions(step: any) {
		return step?.options?.filter((option: any) => option?.selected) || [];
	}

	resetOptions() {
		this.steps.forEach((step: any) => {
			step?.options?.forEach((option: any) => {
				delete option?.selected;
			});
		});
	}

	async importCode() {
		const code = (this.document.getElementById('generalCode') as any).value || '';
		if (!code) return;

		// Check validity
		if (code.split('T').length !== this.steps.length) {
			await this.alertService.open(
				`Il codice che hai inserito non è valido, genera il preventivo da capo per favore`,
				{
					title: '😔',
				}
			);
			this.goToStep({ step: 1 });
			return;
		}

		let countStep = 0;
		code.split('T').forEach((codeStep: any) => {
			const step = this.steps[countStep];
			codeStep.split('X').forEach((codeOption: any) => {
				const option: any = step.options[codeOption - 1];
				if (option) option.selected = true;
			});
			countStep++;
		});

		// Check all filled
		if (!this.checkAllFilled()) {
			await this.alertService.open(
				`Il codice che hai inserito non è valido, genera il preventivo da capo per favore`,
				{
					title: '😔',
				}
			);
			this.resetOptions();
			this.goToStep({ step: 1 });
			return;
		}

		// Save in actual code
		this.actualCode = code;

		// Go to final step
		this.goToStep('final');
	}

	exportCode() {
		let code = '';
		this.steps.forEach((step: any) => {
			let arr: any = [];
			let count = 1;
			step.options.forEach((opt: any) => {
				if (opt?.selected) arr.push(count);
				count += 1;
			});
			code += arr.join('X');
			if (step.step !== this.steps.length) code += 'T';
		});
		return code;
	}

	inviaPreventivo() {
		const str = 'Codice preventivo: ' + this.exportCode();
		localStorage.setItem('contact-message', str);
		this.routerService.open('contatti/idea');
	}

	inviaWhatsapp() {
		const str = 'Codice preventivo: ' + this.exportCode();
		this.whatsappService.open(str);
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [PreventivoComponent],
	exports: [PreventivoComponent],
	imports: [CommonModule, TranslateModule],
})
export class PreventivoModule {}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { PreventivoModule } from '@components/preventivo/preventivo.component';

/*================================================================
# MODULES
================================================================*/
import { SwiperJSModule } from '@components/swiper/swiper.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { ModalService } from '@shared/services/modal.service';
import { RouterService } from '@shared/services/router.service';
import { ToolsService } from '@shared/services/tools.service';

@Component({
	selector: 'app-section',
	templateUrl: './section.component.html',
	styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
	@Input() section: any;
	@Output() buttonClicked = new EventEmitter();

	constructor(
		public toolsService: ToolsService,
		private modalService: ModalService,
		private routerService: RouterService
	) {}

	/*================================================================
	# BUTTON CLICKED
	================================================================*/
	btnClick(action: string, text: string) {
		if (action === 'url') {
			if (text.includes('https')) {
				window.open(text, '_blank', 'noreferrer');
			} else {
				this.routerService.open(text);
			}
		} else if (action === 'emit') {
			this.buttonClicked.emit(text);
		}
	}

	/*================================================================
	# ZOOM IMAGE
	================================================================*/
	zoomImage(image: any) {
		if (!image) return;
		this.modalService.open('image-preview', {
			fullscreen: true,
			header: false,
			miniClose: true,
			dismissable: true,
			data: {
				title: image?.mediaDescription,
				image: image?.mediaUrl,
			},
		});
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [SectionComponent],
	exports: [SectionComponent],
	imports: [CommonModule, SwiperJSModule, PipesModule, PreventivoModule],
})
export class SectionModule {}

<div
	class="box"
	[class.activable]="element?.preview && element?.detail"
	[class.active]="active"
	[style.height]="height"
	[style.width]="width"
	(click)="toggleBox()">
	<div class="header" *ngIf="element?.title || element?.detail">
		<h2>{{ element?.title }}</h2>
		<div class="button"></div>
	</div>

	<div class="main preview" *ngIf="element?.preview">
		<ng-container *ngTemplateOutlet="card; context: { el: element?.preview }"></ng-container>
	</div>

	<div class="main detail" *ngIf="element?.detail">
		<ng-container *ngTemplateOutlet="card; context: { el: element?.detail }"></ng-container>
	</div>
</div>

<!--===============================================================
# CARD TEMPLATE
================================================================-->
<ng-template #card let-el="el">
	<p *ngIf="el?.text" [innerHtml]="el?.text"></p>
	<img *ngIf="el?.image" [alt]="el?.text" [src]="el?.image" />
	<div class="button-container" *ngIf="el?.buttons?.length > 0">
		<ng-container *ngFor="let button of el?.buttons">
			<button
				*ngIf="!button?.hide"
				type="button"
				[ngClass]="button?.class"
				(click)="buttonClicked($event, button?.id)"
				[innerHtml]="button?.text"></button>
		</ng-container>
	</div>
</ng-template>

<div class="blog-container animate fadeInUp">
	<h2 (click)="routerService.open('blog')">
		Dal mio blog
		<img src="assets/icons/chevron-right-white.svg" />
	</h2>

	<div class="blog-row-container">
		<app-card [type]="'blog'" [square]="true" size="sm" [element]="post" *ngFor="let post of posts"></app-card>
	</div>
</div>

import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { DividerModule } from '@components/divider/divider.component';

/*================================================================
# MODULES
================================================================*/
import { SwiperJSModule } from '@components/swiper/swiper.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { ToolsService } from '@shared/services/tools.service';

@Component({
	selector: 'app-summary',
	templateUrl: './summary.component.html',
	styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent {
	@Input() page: any;

	constructor(public toolsService: ToolsService) {}

	/*================================================================
	# EXIST SUMMARY
	================================================================*/
	existsSummary() {
		if (!this.page) return false;
		return this.page.sections.filter((x: any) => x.title)?.length > 0;
	}

	/*================================================================
	# NAVIGATION
	================================================================*/
	navigateTo(target: string) {
		const url = window.location.href.replace('/#/', '').split('#')[0];
		window.location.href = url + '#' + target;
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [SummaryComponent],
	exports: [SummaryComponent],
	imports: [CommonModule, SwiperJSModule, PipesModule, DirectivesModule, DividerModule],
})
export class SummaryModule {}

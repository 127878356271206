/*================================================================
# MODULES
================================================================*/
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

// NG Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// ReCaptcha
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

/*================================================================
# COMPONENTS
================================================================*/
import { AppComponent } from './app.component';
import { SidebarComponent } from '@shared/components/sidebar/sidebar.component';
import { FooterModule } from '@shared/components/footer/footer.component';
import { environment } from '@env/environment';
import { HeaderModule } from '@components/header/header.component';

// AoT requires an exported function for factories
const httpLoaderFactory = (http: HttpClient): TranslateHttpLoader =>
	new TranslateHttpLoader(http, './assets/i18n/', '.json');

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [AppComponent, SidebarComponent],
	imports: [
		AppRoutingModule,
		BrowserModule,
		FormsModule,
		FooterModule,
		HeaderModule,
		HttpClientModule,
		RecaptchaV3Module,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	bootstrap: [AppComponent],
	providers: [
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.recaptcha.siteKey,
		},
	],
})
export class AppModule {}

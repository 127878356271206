import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { v4 as uuid } from 'uuid';

/*================================================================
# SERVICES
================================================================*/
import { RouterService } from './router.service';
import { Modal, initialModal } from '../models/modal';

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	prevBodyOverflow: any = null;
	eventRef: any = null;

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector,
		private routerService: RouterService
	) {}

	/*================================================================
	# OPEN MODAL
	================================================================*/
	open(path: string, options?: Modal) {
		const el = this.routerService.get(path);
		if (!el) {
			return;
		}

		// Include default options
		options = { ...initialModal, ...options };

		// Generate modal
		return new Promise((resolve, reject) => {
			const modal = this.generateElement({
				...options,
				id: uuid(),
				component: el.component,
				title: options?.title ? options.title : el?.data?.name,
			});
			resolve(modal);
		});
	}

	/*================================================================
	# GENERATE MODAL
	================================================================*/
	generateElement(obj: any) {
		return new Promise((resolve, reject) => {
			// Create modal container
			const modal: any = document.createElement('div');
			modal.setAttribute('id', obj.id);
			modal.setAttribute('class', 'ark-modal-container');

			// Create modal content
			const modalContent = document.createElement('div');
			modalContent.classList.add('ark-modal');
			if (!obj.header) {
				modalContent.classList.add('no-header');
			}
			if (obj.draggable) {
				modalContent.classList.add('draggable');
			}
			if (obj.fullscreen) {
				modalContent.classList.add('fullscreen');
				this.prevBodyOverflow = document.body.style.overflow;
				document.body.style.overflow = 'hidden';
			}
			if (obj.resizable) {
				modalContent.classList.add('resizable');
			}
			if (obj.animation) {
				modal.classList.add('animate');
			}
			if (obj.height !== '0') {
				modalContent.style.height = obj.height;
				modalContent.style.marginTop = parseInt(obj.height, 10) / -2 + 'px';
			}
			if (obj.width !== '0') {
				modalContent.style.width = obj.width;
				modalContent.style.marginLeft = parseInt(obj.width, 10) / -2 + 'px';
			}
			modal.appendChild(modalContent);

			// Create header
			if (obj.header) {
				const modalHeader = document.createElement('header');
				modalHeader.innerHTML = obj.title;
				modalContent.appendChild(modalHeader);
				modalHeader?.addEventListener('click', (e: MouseEvent) => this.activeModal(modal.id, e));

				// Header buttons
				const btnContainer = document.createElement('div');
				btnContainer.setAttribute('class', 'ark-btn-container');
				modalHeader.appendChild(btnContainer);

				// Create resize icon
				if (obj.fullscreenBtn) {
					const btnResize = document.createElement('button');
					btnResize.setAttribute('type', 'button');
					btnResize.setAttribute('class', 'ark-btn-resize btn btn-dark btn-sm');
					btnResize.innerHTML = '<i class="bi bi-aspect-ratio"></i>';
					btnContainer.appendChild(btnResize);
					modal
						?.getElementsByClassName('ark-btn-resize')[0]
						.addEventListener('click', () => this.resize(modal.id));
				}

				// Create close icon
				if (obj.closeButton) {
					const btnClose = document.createElement('button');
					btnClose.setAttribute('type', 'button');
					btnClose.setAttribute('class', 'ark-btn-close small');
					btnClose.innerHTML = '<i class="bi bi-x"></i>';
					btnContainer.appendChild(btnClose);
					modal?.getElementsByClassName('ark-btn-close')[0].addEventListener('click', () => {
						this.close(modal.id);
						resolve({ role: 'dismiss', data: {} });
					});
				}
			}

			// Create mini close button
			if (obj.miniClose) {
				const btnMiniClose = document.createElement('div');
				btnMiniClose.setAttribute('class', 'ark-mini-btn-close mini-close');
				btnMiniClose.innerHTML = '<i class="bi bi-x"></i>';
				modalContent.appendChild(btnMiniClose);

				setTimeout(() => {
					btnMiniClose.style.zIndex = this.getMaxZIndex() + 1;
				});

				btnMiniClose?.addEventListener('click', () => {
					this.close(modal.id);
					resolve({ role: 'dismiss', data: {} });
				});
			}

			// Create main
			const modalMain = document.createElement('main');
			modalContent.appendChild(modalMain);

			// ForceModalClosure
			const forceClosure = document.createElement('div');
			forceClosure.setAttribute('class', 'modalForceClosure');
			forceClosure.setAttribute('data', JSON.stringify({ role: 'dismiss', data: {} }));
			forceClosure.style.display = 'none';
			modalContent.appendChild(forceClosure);
			modal?.getElementsByClassName('modalForceClosure')[0].addEventListener('click', () => {
				this.close(modal.id);
				const data = forceClosure.getAttribute('data') || JSON.stringify({ role: 'dismiss', data: {} });
				resolve(JSON.parse(data));
			});

			// Insert in body
			document.body.appendChild(modal);
			setTimeout(() => this.activeModal(modal.id));

			if (obj.overlay) {
				// Create modal ovelay
				const modalOverlay = document.createElement('div');
				modalOverlay.setAttribute('class', 'ark-modal-overlay');
				modal.appendChild(modalOverlay);
				if (obj.dismissable) {
					modal?.getElementsByClassName('ark-modal-overlay')[0].addEventListener('click', () => {
						this.close(modal.id);
						resolve({ role: 'dismiss', data: {} });
					});
				}
			}

			// Activate draggable (if enabled)
			if (obj.draggable) {
				this.draggable(modal.id);
			}

			// Insert component inside modal
			const contentRef: any = this.componentFactoryResolver
				.resolveComponentFactory(obj.component)
				.create(this.injector);

			if (obj.data) {
				Object.entries(obj.data).forEach((el: any) => {
					contentRef.instance[el[0]] = el[1];
				});
			}

			// Add listner (keydown)
			if (obj.dismissable) {
				this.eventRef = (event: any) => {
					event.preventDefault();
					if (event.key === 'Enter') {
						const data = { role: 'ok', data: {} };
						this.close('', data);
						resolve(obj);
					} else if (event.key === 'Escape') {
						const data = { role: 'dismiss', data: {} };
						this.close('', data);
						resolve(obj);
					}
				};
				window?.addEventListener('keydown', this.eventRef);
			}

			this.appRef.attachView(contentRef.hostView);
			const contentElem = (contentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
			modal?.getElementsByTagName('main')[0].appendChild(contentElem);
		});
	}

	/*================================================================
	# GET MAX ZINDEX
	================================================================*/
	getMaxZIndex() {
		let maxZIndex = 0;
		Array.from(document.getElementsByClassName('ark-modal-container')).forEach((x: any) => {
			const actZ = parseInt(x.style.zIndex, 10) || 100;
			if (actZ > maxZIndex) {
				maxZIndex = actZ;
			}
		});
		Array.from(document.getElementsByClassName('ark-alert-container')).forEach((x: any) => {
			const actZ = parseInt(x.style.zIndex, 10) || 100;
			if (actZ > maxZIndex) {
				maxZIndex = actZ;
			}
		});
		return maxZIndex + '';
	}

	/*================================================================
	# ACTIVATE MODAL
	================================================================*/
	activeModal(id: string, event?: MouseEvent) {
		if (event) {
			event.stopPropagation();
		}

		const el: any = document.getElementById(id) || '';
		if (el) {
			Array.from(document.getElementsByClassName('ark-modal-container')).forEach(x =>
				x.classList.remove('active')
			);
			el.classList.add('active');
			const actualZIndex = parseInt(el.style.zIndex || 0, 10);
			const maxZIndex = parseInt(this.getMaxZIndex(), 10);
			if (actualZIndex < maxZIndex) {
				el.style.zIndex = maxZIndex + 1;
			}
		}
	}

	/*================================================================
	# ACTIVE MAX MODAL
	================================================================*/
	activeMaxModal() {
		let el: any = null;
		let maxZIndex = 0;
		Array.from(document.getElementsByClassName('ark-modal-container')).forEach((x: any) => {
			const actZ = parseInt(x.style.zIndex, 10) || 100;
			if (actZ > maxZIndex) {
				maxZIndex = actZ;
				el = x;
			}
		});
		if (el) {
			this.activeModal(el.id);
		}
	}

	/*================================================================
	# CLOSE MODAL
	================================================================*/
	close(id: string = '', data: any = { role: 'dismiss', data: {} }) {
		// REMOVE listner (keydown)
		window?.removeEventListener('keydown', this.eventRef);

		if (!id) {
			const el: any = document.getElementById(
				document.getElementsByClassName('ark-modal-container active')[0].id
			);
			const forceClosure = el?.getElementsByClassName('modalForceClosure')[0];
			forceClosure.setAttribute('data', JSON.stringify(data));
			forceClosure.click();
			return;
		}
		document.body.style.overflow = this.prevBodyOverflow;
		document.getElementById(id)?.classList.add('close');
		setTimeout(() => {
			document.getElementById(id)?.remove();
			this.activeMaxModal();
		}, 500);
	}

	closeAll() {
		const modals: any = Array.from(document.getElementsByClassName('ark-modal-container'));
		modals.forEach((el: any) => {
			const forceClosure = el?.getElementsByClassName('modalForceClosure')[0];
			forceClosure.click();
		});
	}

	/*================================================================
	# RESIZE MODAL
	================================================================*/
	resize(id: string) {
		const container: any = document.getElementById(id);
		const el = container?.getElementsByClassName('ark-modal')[0];
		if (el?.classList.contains('fullscreen')) {
			el?.classList.remove('fullscreen');
		} else {
			el?.classList.add('fullscreen');
			container.style.top = 0 + 'px';
			container.style.left = 0 + 'px';
		}
	}

	/*================================================================
	# DRAGGABLE
	================================================================*/
	draggable(id: string) {
		let x = 0;
		let y = 0;
		const ele: any = document.getElementById(id);
		const mouseDownHandler = (e: any) => {
			if (e.target.localName !== 'header') {
				return;
			}
			x = e.clientX;
			y = e.clientY;
			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};

		const mouseMoveHandler = (e: any) => {
			const dx = e.clientX - x;
			const dy = e.clientY - y;

			const coords = ele.getElementsByClassName('ark-modal')[0].getBoundingClientRect();

			const valX = coords.x < 0 ? 1 : coords.right > window.innerWidth ? window.innerWidth - coords.right : dx;
			const moveX = ele.offsetLeft + valX;

			const valY =
				coords.y < 0 ? 1 : coords.bottom > window.innerHeight ? window.innerHeight - coords.bottom : dy;
			const moveY = ele.offsetTop + valY;

			ele.style.top = `${moveY}px`;
			ele.style.left = `${moveX}px`;

			x = e.clientX;
			y = e.clientY;
		};

		const mouseUpHandler = () => {
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		};

		ele?.addEventListener('mousedown', mouseDownHandler);
	}
}

<section>
	<div class="wrapper">
		<h2>Ti piacciono i servizi che offro?</h2>
		<p>Mi fa sempre piacere ricevere messaggi, cerco sempre di rispondere il più rapidamente possibile!</p>

		<div class="actions">
			<div class="action" (click)="routerService.open('contatti/info')">
				<img alt="Icona email" src="assets/icons/mail-white.svg" />
				<h3>Compila il form nella pagina contatti</h3>
			</div>
			<div class="action bg-green" (click)="whatsappService.open()" *ngIf="environment.whatsapp.active">
				<img alt="Icona whatsapp" src="assets/icons/whatsapp-white.svg" />
				<h3>Scrivimi su WhatsApp</h3>
			</div>
		</div>
	</div>
</section>

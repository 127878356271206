import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MenuModule } from '@components/menu/menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { ToggleModule } from '@shared/components/toggle/toggle.component';
import { AlertService } from '@shared/services/alert.service';
import { LoaderService } from '@shared/services/loader.service';
import { MailService } from '@shared/services/mail.service';
import { ToastService } from '@shared/services/toast.service';
import { RecaptchaService } from '@shared/services/recaptcha.service';
import { ModalService } from '@shared/services/modal.service';

@Component({
	selector: 'app-form-contacts',
	templateUrl: './form-contacts.component.html',
	styleUrls: ['./form-contacts.component.scss'],
})
export class FormContactsComponent {
	@Input() type: string = '';

	testoDefault: string = localStorage.getItem('contact-message') || '';

	formSubmitted: boolean = false;
	form = this.fb.group({
		nome: ['', [Validators.required]],
		cognome: ['', [Validators.required]],
		email: ['', [Validators.required, Validators.email]],
		telefono: [''],
		testo: [this.testoDefault, [Validators.required]],
		privacy: ['', [Validators.required]],
	});

	constructor(
		private fb: FormBuilder,
		private alertService: AlertService,
		private mailService: MailService,
		private loaderService: LoaderService,
		private toastService: ToastService,
		private recaptchaService: RecaptchaService,
		private modalService: ModalService
	) {
		localStorage.removeItem('contact-message');
	}

	async sumbitForm() {
		this.formSubmitted = true;
		if (!this.form.valid) {
			// Errors
			const errors: any = [];
			Object.keys(this.form.controls).forEach(key => {
				const controlErrors: any = this.form.get(key);
				if (controlErrors?.errors !== null) {
					Object.keys(controlErrors?.errors).forEach(keyError => {
						errors.push(`${key.toUpperCase()} ${this.getErrorMessage(keyError)}`);
					});
				}
			});
			const errorMessage = errors.join('<br>');
			this.alertService.open(errorMessage, {
				title: 'Sono stati riscontrati i seguenti errori',
			});
			return;
		}

		await this.sendEmail();
	}

	// Funzione di utilità per ottenere messaggi di errore leggibili
	getErrorMessage(errorKey: string): string {
		const errorMessages: any = {
			required: 'è obbligatorio.',
			email: 'deve essere un indirizzo email valido.',
		};
		return errorMessages[errorKey] || '';
	}

	async sendEmail() {
		const message = `
			<style>
				.message-body{
					padding: 20px;
					margin: 20px;
					border: 1px solid #CCCCCC;
					background-color: #FFFFFF;
				}

				h2, h3{
					display: block;
					padding: 0;
					margin: 0;
					margin-bottom: 10px;
				}
			</style>

			<body>
				<div class="message-body">
					<h2>Hai ricevuto un nuovo contatto dal sito web: <strong>arkastech.it</strong></h2>
					<h3>Oggetto: ${this.type}</h3>
					<hr>
					<h3>Informazioni del mittente:</h3>
					Nome: <strong>${this.form.value.nome}</strong><br>
					Cognome: <strong>${this.form.value.cognome}</strong><br>
					Email: <strong>${this.form.value.email}</strong><br>
					Telefono: <strong>${this.form.value.telefono || 'non inserito'}</strong><br>
					<hr>
					<h3>Testo del messaggio (lasciato dall'utente):</h3>
					${this.form.value.testo}
				</div>
			</body>
		`;

		const body = await this.recaptchaService.prepareBody({
			subject: 'Nuovo contatto dal sito: arkastech.it',
			message,
		});

		// Form valid
		this.loaderService.show();
		const res = await this.mailService.send(body);

		if (res === true) {
			this.loaderService.hide();
			this.form.reset();
			this.formSubmitted = false;
			this.toastService.open('Messaggio inviato con successo!', 'success');
		} else {
			this.loaderService.hide();
			this.toastService.open("Errore nell'invio del messaggio!");
		}
	}

	async showPrivacy() {
		this.modalService.open('privacy-policy', {
			fullscreen: true,
			header: false,
			miniClose: true,
			data: { inModal: true },
		});
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [FormContactsComponent],
	exports: [FormContactsComponent],
	imports: [CommonModule, TranslateModule, MenuModule, ToggleModule, ReactiveFormsModule],
})
export class FormContactsModule {}

import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@shared/directives/directives.module';

@Component({
	selector: 'app-skeleton',
	templateUrl: './skeleton.component.html',
	styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent {
	@Input() height: string = '200px';
	@Input() width: string = '100%';

	constructor() {}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [SkeletonComponent],
	exports: [SkeletonComponent],
	imports: [CommonModule, TranslateModule, DirectivesModule],
})
export class SkeletonModule {}

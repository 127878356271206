import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { v4 as uuid } from 'uuid';

/*================================================================
# SERVICES
================================================================*/
import { RouterService } from './router.service';
import { Sidebar, initialSidebar } from '../models/sidebar';

@Injectable({
	providedIn: 'root',
})
export class SidebarService {
	prevBodyOverflow: any = null;

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector,
		private routerService: RouterService
	) {}

	/*================================================================
	# OPEN MODAL
	================================================================*/
	open(path: string, options?: Sidebar) {
		const el = this.routerService.get(path);
		if (!el) {
			return;
		}

		// Include default options
		options = { ...initialSidebar, ...options };

		// Generate sidebar
		return new Promise((resolve, reject) => {
			const sidebar = this.generateElement({
				...options,
				id: uuid(),
				component: el.component,
				title: options?.title ? options.title : el?.data?.name,
			});
			resolve(sidebar);
		});
	}

	/*================================================================
	# GENERATE MODAL
	================================================================*/
	generateElement(obj: any) {
		return new Promise((resolve, reject) => {
			// Create sidebar container
			const sidebar: any = document.createElement('div');
			sidebar.setAttribute('id', obj.id);
			sidebar.setAttribute('class', 'ark-sidebar-container');

			// Create sidebar content
			const sidebarContent = document.createElement('div');
			sidebarContent.classList.add('ark-sidebar');
			if (!obj.header) {
				sidebarContent.classList.add('no-header');
			}
			if (obj.animation) {
				sidebar.classList.add('animate');
			}
			if (obj.width !== '0') {
				sidebarContent.style.width = obj.width;
				sidebarContent.style.marginLeft = parseInt(obj.width, 10) / -2 + 'px';
			}
			sidebar.appendChild(sidebarContent);
			this.prevBodyOverflow = document.body.style.overflow;
			document.body.style.overflow = 'hidden';

			// Create header
			if (obj.header) {
				const sidebarHeader = document.createElement('header');
				sidebarContent.appendChild(sidebarHeader);
				sidebarHeader?.addEventListener('click', (e: MouseEvent) => this.activeModal(sidebar.id, e));

				// Header buttons
				const btnContainer = document.createElement('div');
				btnContainer.setAttribute('class', 'ark-btn-container');
				sidebarHeader.appendChild(btnContainer);

				// Header text
				const headerText = document.createElement('span');
				headerText.innerHTML = obj.title;
				sidebarHeader.appendChild(headerText);

				// Create close icon
				if (obj.closeButton) {
					const btnClose = document.createElement('button');
					btnClose.setAttribute('type', 'button');
					btnClose.setAttribute('class', 'ark-btn-close small');
					btnClose.innerHTML = '<i class="bi bi-x"></i>';
					btnContainer.appendChild(btnClose);
					sidebar?.getElementsByClassName('ark-btn-close')[0].addEventListener('click', () => {
						this.close(sidebar.id);
						resolve({ role: 'dismiss', data: {} });
					});
				}
			}

			// Create mini close button
			if (obj.miniClose) {
				const btnMiniClose = document.createElement('div');
				btnMiniClose.setAttribute('class', 'ark-mini-btn-close small');
				btnMiniClose.innerHTML = '<i class="bi bi-x"></i>';
				sidebarContent.appendChild(btnMiniClose);
				btnMiniClose?.addEventListener('click', () => {
					this.close(sidebar.id);
					resolve({ role: 'dismiss', data: {} });
				});
			}

			// Create main
			const sidebarMain = document.createElement('main');
			sidebarContent.appendChild(sidebarMain);

			// ForceModalClosure
			const forceClosure = document.createElement('div');
			forceClosure.setAttribute('class', 'sidebarForceClosure');
			forceClosure.setAttribute('data', JSON.stringify({ role: 'dismiss', data: {} }));
			forceClosure.style.display = 'none';
			sidebarContent.appendChild(forceClosure);
			sidebar?.getElementsByClassName('sidebarForceClosure')[0].addEventListener('click', () => {
				this.close(sidebar.id);
				const data = forceClosure.getAttribute('data') || JSON.stringify({ role: 'dismiss', data: {} });
				resolve(JSON.parse(data));
			});

			// Insert in body
			document.body.appendChild(sidebar);
			setTimeout(() => this.activeModal(sidebar.id));

			if (obj.overlay) {
				// Create sidebar ovelay
				const sidebarOverlay = document.createElement('div');
				sidebarOverlay.setAttribute('class', 'ark-sidebar-overlay');
				sidebar.appendChild(sidebarOverlay);
				if (obj.dismissable) {
					sidebar?.getElementsByClassName('ark-sidebar-overlay')[0].addEventListener('click', () => {
						this.close(sidebar.id);
						resolve({ role: 'dismiss', data: {} });
					});
				}
			}

			// Activate draggable (if enabled)
			if (obj.draggable) {
				this.draggable(sidebar.id);
			}

			// Insert component inside sidebar
			const contentRef: any = this.componentFactoryResolver
				.resolveComponentFactory(obj.component)
				.create(this.injector);

			if (obj.data) {
				Object.entries(obj.data).forEach((el: any) => {
					contentRef.instance[el[0]] = el[1];
				});
			}

			this.appRef.attachView(contentRef.hostView);
			const contentElem = (contentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
			sidebar?.getElementsByTagName('main')[0].appendChild(contentElem);
		});
	}

	/*================================================================
	# GET MAX ZINDEX
	================================================================*/
	getMaxZIndex() {
		let maxZIndex = 0;
		Array.from(document.getElementsByClassName('ark-sidebar-container')).forEach((x: any) => {
			const actZ = parseInt(x.style.zIndex, 10) || 100;
			if (actZ > maxZIndex) {
				maxZIndex = actZ;
			}
		});
		Array.from(document.getElementsByClassName('ark-alert-container')).forEach((x: any) => {
			const actZ = parseInt(x.style.zIndex, 10) || 100;
			if (actZ > maxZIndex) {
				maxZIndex = actZ;
			}
		});
		return maxZIndex + '';
	}

	/*================================================================
	# ACTIVATE MODAL
	================================================================*/
	activeModal(id: string, event?: MouseEvent) {
		if (event) {
			event.stopPropagation();
		}

		const el: any = document.getElementById(id) || '';
		if (el) {
			Array.from(document.getElementsByClassName('ark-sidebar-container')).forEach(x =>
				x.classList.remove('active')
			);
			el.classList.add('active');
			const actualZIndex = parseInt(el.style.zIndex || 0, 10);
			const maxZIndex = parseInt(this.getMaxZIndex(), 10);
			if (actualZIndex < maxZIndex) {
				el.style.zIndex = maxZIndex + 1;
			}
		}
	}

	/*================================================================
	# ACTIVE MAX MODAL
	================================================================*/
	activeMaxModal() {
		let el: any = null;
		let maxZIndex = 0;
		Array.from(document.getElementsByClassName('ark-sidebar-container')).forEach((x: any) => {
			const actZ = parseInt(x.style.zIndex, 10) || 100;
			if (actZ > maxZIndex) {
				maxZIndex = actZ;
				el = x;
			}
		});
		if (el) {
			this.activeModal(el.id);
		}
	}

	/*================================================================
	# CLOSE MODAL
	================================================================*/
	close(id: string = '', data: any = { role: 'dismiss', data: {} }) {
		if (!id) {
			const el: any = document.getElementById(
				document.getElementsByClassName('ark-sidebar-container active')[0].id
			);
			const forceClosure = el?.getElementsByClassName('sidebarForceClosure')[0];
			forceClosure.setAttribute('data', JSON.stringify(data));
			forceClosure.click();
			return;
		}

		document.body.style.overflow = this.prevBodyOverflow;
		document.getElementById(id)?.classList.add('close');
		setTimeout(() => {
			document.getElementById(id)?.remove();
			this.activeMaxModal();
		}, 500);
	}

	closeAll() {
		const sidebars: any = Array.from(document.getElementsByClassName('ark-sidebar-container'));
		sidebars.forEach((el: any) => {
			const forceClosure = el?.getElementsByClassName('sidebarForceClosure')[0];
			forceClosure.click();
		});
	}

	/*================================================================
	# RESIZE MODAL
	================================================================*/
	resize(id: string) {
		const container: any = document.getElementById(id);
		const el = container?.getElementsByClassName('ark-sidebar')[0];
		if (el?.classList.contains('fullscreen')) {
			el?.classList.remove('fullscreen');
		} else {
			el?.classList.add('fullscreen');
			container.style.top = 0 + 'px';
			container.style.left = 0 + 'px';
		}
	}

	/*================================================================
	# DRAGGABLE
	================================================================*/
	draggable(id: string) {
		let x = 0;
		let y = 0;
		const ele: any = document.getElementById(id);
		const mouseDownHandler = (e: any) => {
			if (e.target.localName !== 'header') {
				return;
			}
			x = e.clientX;
			y = e.clientY;
			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};

		const mouseMoveHandler = (e: any) => {
			const dx = e.clientX - x;
			const dy = e.clientY - y;

			const coords = ele.getElementsByClassName('ark-sidebar')[0].getBoundingClientRect();

			const valX = coords.x < 0 ? 1 : coords.right > window.innerWidth ? window.innerWidth - coords.right : dx;
			const moveX = ele.offsetLeft + valX;

			const valY =
				coords.y < 0 ? 1 : coords.bottom > window.innerHeight ? window.innerHeight - coords.bottom : dy;
			const moveY = ele.offsetTop + valY;

			ele.style.top = `${moveY}px`;
			ele.style.left = `${moveX}px`;

			x = e.clientX;
			y = e.clientY;
		};

		const mouseUpHandler = () => {
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		};

		ele?.addEventListener('mousedown', mouseDownHandler);
	}
}

<div class="related-container">
	<div class="header">
		<h2>Altri progetti correlati</h2>
		<h3>Ecco un elenco di altri progetti che ho sviluppato...</h3>
	</div>

	<swiper-container slides-per-view="auto" free-mode="true" centered-slides="true" space-between="30">
		<swiper-slide *ngFor="let slide of related">
			<app-card [square]="true" [element]="slide"></app-card>
		</swiper-slide>
	</swiper-container>
</div>

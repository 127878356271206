import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { WhatsappService } from '@services/whatsapp.service';
import { DirectivesModule } from '@shared/directives/directives.module';
import { RouterService } from '@shared/services/router.service';

@Component({
	selector: 'app-me',
	templateUrl: './me.component.html',
	styleUrls: ['./me.component.scss'],
})
export class MeComponent {
	@Input() links: boolean = true;
	@Input() animation: boolean = true;
	years = new Date().getFullYear() - new Date('2010').getFullYear();
	environment = environment;

	constructor(
		public routerService: RouterService,
		public whatsappService: WhatsappService
	) {}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [MeComponent],
	exports: [MeComponent],
	imports: [CommonModule, TranslateModule, DirectivesModule],
})
export class MeModule {}

import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { RouterService } from '@shared/services/router.service';

@Directive({
	selector: '[href]',
})
export class LinkDirective implements OnInit {
	@Input() href: any;

	constructor(
		private routerService: RouterService,
		private elementRef: ElementRef
	) {}

	ngOnInit(): void {
		this.elementRef.nativeElement.setAttribute('href', this.href);
	}

	@HostListener('click', ['$event'])
	noop(event: MouseEvent) {
		if (this.href.indexOf('http') === -1 && this.href.indexOf('#') !== 0) {
			this.routerService.open(this.href);
			event.preventDefault();
		} else if (this.href.indexOf('#') !== 0) {
			window.open(this.href, '_blank', 'noreferrer');
			event.preventDefault();
		}
	}
}

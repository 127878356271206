<!--===============================================================
# GENERAL
================================================================-->
<section [class.bg]="section?.type === 'swiper' || section?.background" [class.separator]="section?.separator">
	<div class="anchor" [id]="toolsService.sanitize(section?.title || '')"></div>

	<!--===============================================================
	# COMPONENT
	================================================================-->
	<ng-container *ngIf="section?.type === 'component'">
		<app-preventivo *ngIf="section?.title === 'preventivo'"></app-preventivo>
	</ng-container>

	<!--===============================================================
	# SECTIONS
	================================================================-->
	<ng-container *ngIf="section?.type !== 'component'">
		<div class="section-container" [class.wrapper]="section?.type !== 'swiper'">
			<div *ngIf="section?.separator" class="separator"></div>

			<!-- HEADER -->
			<div class="header" *ngIf="section?.title || section?.description">
				<h2 *ngIf="section?.title">{{ section?.title }}</h2>
				<h3 *ngIf="section?.description" [innerHtml]="section?.description"></h3>
			</div>
			<!-- END HEADER -->

			<!-- CONTENT -->
			<ng-container *ngIf="section?.type === 'swiper'">
				<ng-container *ngTemplateOutlet="swiper; context: { section }"></ng-container>
			</ng-container>

			<ng-container *ngIf="section?.type !== 'swiper'">
				<ng-container *ngTemplateOutlet="element; context: { section }"></ng-container>
			</ng-container>
			<!-- END CONTENT -->
		</div>
	</ng-container>
</section>

<!--===============================================================
# TEMPLATE: SWIPER
================================================================-->
<ng-template #swiper let-section="section">
	<div class="swiper">
		<app-swiper (eventSlideClick)="zoomImage($event)" [section]="section"></app-swiper>
	</div>
</ng-template>

<!--===============================================================
# TEMPLATE: ELEMENT
================================================================-->
<ng-template #element let-section="section">
	<div [class]="section?.type" *ngIf="section?.elements?.length > 0">
		<div
			[ngClass]="element?.mediaUrl ? 'img-' + element?.mediaPosition : ''"
			class="element"
			*ngFor="let element of section?.elements">
			<!--===============================================================
			# ICON
			================================================================-->
			<ng-container *ngIf="element?.icon">
				<div class="element-icon">
					<img [alt]="element?.title || 'image'" [src]="element?.icon" />
				</div>
			</ng-container>

			<!--===============================================================
			# IMAGE
			================================================================-->
			<ng-container *ngIf="element?.mediaType === 'img' && element?.mediaUrl">
				<div class="img-container" (click)="zoomImage(element)">
					<img
						[alt]="element?.mediaDescription || element?.title"
						*ngIf="element?.mediaUrl"
						[src]="element?.mediaUrl" />
					<div *ngIf="element?.mediaDescription" class="img-description">
						{{ element?.mediaDescription }}
					</div>
				</div>
			</ng-container>

			<!--===============================================================
			# INSTAGRAM
			================================================================-->
			<ng-container *ngIf="element?.mediaType === 'instagram'">
				<iframe
					class="instagram-media instagram-media-rendered"
					id="instagram-embed-0"
					[src]="'https://www.instagram.com/tv/' + element?.mediaUrl + '/embed/captioned' | safe"
					allowtransparency="true"
					allowfullscreen="true"
					frameborder="0"
					data-instgrm-payload-id="instagram-media-payload-0"
					scrolling="no"></iframe>
			</ng-container>

			<div class="content">
				<h3 class="title" *ngIf="element?.title">
					{{ element?.title }}
				</h3>
				<ng-container *ngIf="element?.text">
					<br *ngIf="element?.title" />
					<span [innerHTML]="element?.text | nl2br | keepHtml"></span>
				</ng-container>

				<!--===============================================================
				# BUTTONS
				================================================================-->
				<ng-container *ngIf="element?.buttons">
					<div class="buttons" [class.marginTop]="element?.text || element?.title">
						<ng-container *ngFor="let button of element?.buttons">
							<button
								*ngIf="!button?.hide"
								(click)="btnClick(button?.action, button?.actionText)"
								[class.iconEnd]="button?.iconUrl && button?.iconPosition === 'end'"
								[ngClass]="button?.class"
								type="button">
								<img *ngIf="button?.iconUrl" [src]="button?.iconUrl" [alt]="button?.text" />
								{{ button?.text }}
							</button>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</ng-template>

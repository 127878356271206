import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';

/*================================================================
# MODULES
================================================================*/
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@shared/directives/directives.module';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
	@Input() mode = 'dark';
	@Input() elements: any = [];
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [BreadcrumbComponent],
	imports: [CommonModule, TranslateModule, DirectivesModule],
	exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}

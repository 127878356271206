import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Component({
	selector: 'app-toggle',
	templateUrl: './toggle.component.html',
	styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
	@Input() value = '';
	@Input() checked = false;

	@Output() eventChange = new EventEmitter();

	id = uuid();

	constructor() {}

	/*================================================================
	# ON CHANGE
	================================================================*/
	change(event: any) {
		this.checked = event.target.checked;
		this.eventChange.emit(this.checked);
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [ToggleComponent],
	imports: [CommonModule],
	exports: [ToggleComponent],
})
export class ToggleModule {}

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class CookieScriptService {
	constructor(@Inject(DOCUMENT) private document: Document) {}

	init() {
		if (!environment.cookieScript.active) return;
		const script = this.document.createElement('script');
		script.src = '//cdn.cookie-script.com/s/' + environment.cookieScript.key + '.js';
		script.async = true;
		this.document.getElementsByTagName('head')[0].appendChild(script);
	}
}

<footer>
	<div class="wrapper flex-center">
		<div class="info">
			<span class="name" *ngIf="environment.app.footer.showName">
				{{ environment.app.name }}
			</span>
			<span class="version" *ngIf="environment.app.footer.showVersion">[v{{ packageInfo.version }}]</span>
			- © 2018 ~ {{ year }} • Arkas di Barbato Cristian – P. I. 14594091002 -
			<a href="privacy-policy">Privacy policy + Cookie policy</a>
		</div>

		<div class="loghi">
			<img
				(click)="openLink('https://arkastech.it')"
				height="40"
				width="40"
				alt="Sviluppato da Arkas (visita il sito)"
				tooltip="Sviluppato da Arkas (visita il sito)"
				src="/assets/img/arkas.svg" />
		</div>
	</div>

	<div class="jumpToTop fadeInUp" (click)="jumpToTop()">
		<img alt="Button return to top" src="assets/icons/chevron-up-white.svg" />
	</div>
</footer>

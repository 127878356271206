import { CommonModule } from '@angular/common';
import { Component, HostListener, NgModule, OnInit } from '@angular/core';
import { HeaderPageModule } from '@components/header-page/header-page.component';

import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

/*================================================================
# MODULES
================================================================*/
import { TranslateModule } from '@ngx-translate/core';
import { SectionModule } from '@components/section/section.component';
import { SummaryModule } from '@components/summary/summary.component';
import { CardModule } from '@components/card/card.component';
import { BlogService } from './services/blog.service';
import { ActivatedRoute } from '@angular/router';
import { DirectivesModule } from '@shared/directives/directives.module';
import { RouterService } from '@shared/services/router.service';
import { BlogRelatedModule } from './components/blog-related/blog-related.component';
import { MeModule } from '@components/me/me.component';
import { SectionContattamiModule } from '@components/section-contattami/section-contattami.component';
import { SkeletonModule } from '@components/skeleton/skeleton.component';

@Component({
	selector: 'app-blog',
	templateUrl: './blog.component.html',
	styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
	page: any = {
		name: 'Blog',
		description:
			"Questo è il blog di Arkas, qui vengono inseriti tutti gli articoli riguardanti gli accadimenti nella vita dell'Alchimista tecnologico",
		technologies: [],
		image: '',
		imageFit: 'cover',
		url: '',
		type: 'web',
		tags: '',
		customer: '',
		sections: [],
		elements: [],
	};
	breadcrumb: any = [{ name: 'Blog', path: 'blog' }];
	actualCategory: any = null;
	actualTag: any = null;
	loading: boolean = true;

	/*================================================================
	# LOAD MORE POSTS ON SCROLL
	================================================================*/
	skip: number = 1;
	loadingMorePosts: boolean = false;
	endLoading = false;
	@HostListener('window:scroll', ['$event'])
	onScroll() {
		this.scrollCheck();
	}

	constructor(
		public blogService: BlogService,
		private route: ActivatedRoute,
		private routerService: RouterService,
		@Inject(DOCUMENT) private document: Document
	) {}

	/*================================================================
	# INIT
	================================================================*/
	async ngOnInit() {
		this.loading = true;
		await this.loadPage();
		this.loading = false;
	}

	/*================================================================
	# LOAD PAGE
	================================================================*/
	async loadPage() {
		const id = this.route.snapshot.paramMap.get('id');
		const category = this.route.snapshot.paramMap.get('categoryID');
		const tag = this.route.snapshot.paramMap.get('tagID');
		const preview = this.route.snapshot.queryParamMap.get('preview') !== null;

		if (id) {
			// Single page
			try {
				this.page = await this.blogService.getPost(id, preview);
				this.page.id = id;
			} catch (err) {
				this.routerService.open('404');
				return;
			}
			this.breadcrumb = [
				{ name: 'Blog', path: 'blog' },
				{ name: this.page.name, path: this.page.slug },
			];

			// Set SEO
			this.routerService.setSEO({
				name: this.page.name,
				title: this.page.name,
				description: this.page.description,
				date: this.page.date,
				image: this.routerService.domain() + '/' + this.page.image,
				type: 'article',
			});
		} else {
			// Generic blog
			let body = null;
			if (category) body = { type: 'category', id: category };
			if (tag) body = { type: 'tag', id: tag };

			body = { ...body, page: this.skip, page_size: 9 };

			const results: any = await this.blogService.getPosts(body);

			this.page.elements = this.page.elements.concat(results.elements || []);
			this.page.allCategories = results.categories || [];
			this.page.allTags = results.tags || [];
			this.endLoading = this.skip === results.pages;

			// Filter by category
			if (category) this.actualCategory = this.page.allCategories.find((c: any) => c.id === category);

			// Filter by tag
			if (tag) this.actualTag = tag;

			// Set SEO
			this.routerService.setSEO({
				title:
					this.page.name +
					(category ? ' - [Categoria: ' + category + ']' : '') +
					(tag ? ' - [Tag: ' + tag + ']' : ''),
				name:
					this.page.name +
					(category ? ' - [Categoria: ' + category + ']' : '') +
					(tag ? ' - [Tag: ' + tag + ']' : ''),
				description: this.page.description,
				date: this.page.date,
				type: 'article',
			});
		}
	}

	/*================================================================
	# SCROLL CHECK
	================================================================*/
	scrollCheck() {
		if (!this.page?.id && !this.loadingMorePosts && !this.endLoading && !this.loading) {
			const actScroll =
				Math.round(
					window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
				) + window.innerHeight;
			const maxScroll = this.document.body.scrollHeight - window.innerHeight;
			if (actScroll >= maxScroll) this.loadMore();
		}
	}

	/*================================================================
	# LOAD MORE POSTS
	================================================================*/
	async loadMore(page: any = false) {
		this.skip = page ? page : this.skip + 1;
		this.loadingMorePosts = true;
		await this.loadPage();
		this.loadingMorePosts = false;
	}

	/*================================================================
	# OPEN CATEGORY
	================================================================*/
	openCategory(categoryID: string) {
		this.routerService.open('categoria/' + categoryID);
	}

	/*================================================================
	# BACK
	================================================================*/
	back() {
		this.routerService.open('blog');
	}

	/*================================================================
	# TRACK BY FN
	================================================================*/
	trackByFn(index: any, item: any): string {
		return item.id;
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [BlogComponent],
	imports: [
		CommonModule,
		TranslateModule,
		HeaderPageModule,
		SectionModule,
		SummaryModule,
		CardModule,
		DirectivesModule,
		BlogRelatedModule,
		MeModule,
		SectionContattamiModule,
		SkeletonModule,
	],
})
export class BlogModule {}

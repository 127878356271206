import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import packageInfo from '@pak/package';
import { DirectivesModule } from '@shared/directives/directives.module';

import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	packageInfo = packageInfo;
	environment = environment;
	year = new Date().getFullYear();

	constructor(@Inject(DOCUMENT) private document: Document) {}

	/*================================================================
	# INIT
	================================================================*/
	ngOnInit(): void {
		this.document.addEventListener('scroll', () => {
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			const el = document.getElementsByClassName('jumpToTop')[0];
			if (scrollTop > 0) {
				el?.classList.add('active');
			} else {
				el?.classList.remove('active');
			}
		});
	}

	/*================================================================
	# OPEN EXTERNAL LINK
	================================================================*/
	openLink(url: string) {
		window.open(url, '_blank', 'noreferrer');
	}

	/*================================================================
	# JUMP TO TOP
	================================================================*/
	jumpToTop() {
		window.scrollTo(0, 0);
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [FooterComponent],
	imports: [CommonModule, DirectivesModule],
	exports: [FooterComponent],
})
export class FooterModule {}

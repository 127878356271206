import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { HeaderPageModule } from '@components/header-page/header-page.component';

/*================================================================
# MODULES
================================================================*/
import { TranslateModule } from '@ngx-translate/core';
import { SectionModule } from '@components/section/section.component';
import { SummaryModule } from '@components/summary/summary.component';
import { RouterService } from '@shared/services/router.service';
import { BoxModule } from '@components/box/box.component';
import { SidebarService } from '@shared/services/sidebar.service';
import { DirectivesModule } from '@shared/directives/directives.module';

@Component({
	selector: 'app-info',
	templateUrl: './info.component.html',
	styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
	@Input() image: string = '';
	@Input() elements: any = [];

	constructor(
		private routerService: RouterService,
		private sidebarService: SidebarService
	) {}

	ngOnInit() {
		if (this.elements?.length === 0) this.routerService.open('404');
	}

	buttonClicked(info: any) {
		this.sidebarService.close('', info);
		info?.event.stopPropagation();
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [InfoComponent],
	exports: [InfoComponent],
	imports: [
		CommonModule,
		TranslateModule,
		HeaderPageModule,
		SectionModule,
		SummaryModule,
		BoxModule,
		DirectivesModule,
	],
})
export class InfoModule {}

export const APP_CONFIG = {
	production: true,
	environment: 'PRODUCTION',
};

export const environment = {
	api: {
		path: 'https://api.arkastech.it/api/',
		tokenCrypt: false,
		checkToken: false,
	},
	app: {
		name: 'Arkas',
		description: `Realizzazione siti internet e web app professionali. La nostra specialità è lo sviluppo di soluzioni tecnologiche per i nostri partner.`,
		footer: {
			showName: true,
			showVersion: true,
		},
	},
	info: {
		logoUrl: 'assets/icons/favicon.png',
	},
	log: {
		showInfo: false,
		showError: true,
	},
	cookieScript: {
		active: true,
		key: '877de5a0344a9ea9e8ab1347d4ca7e3f',
	},
	googleAnalytics: {
		active: true,
		key: 'UA-112938109-1',
	},
	recaptcha: {
		siteKey: '6LcJ7g0pAAAAAPrvuFYfpXv1K-fxR22F4HmMSFWU',
	},
	mail: {
		api: {
			path: 'email/send',
		},
	},
	whatsapp: {
		active: true,
		number: '+390692916899',
	},
	cdn: {
		image: {
			path: 'https://arkas-tech.imgix.net',
		},
	},
};

import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@shared/pipes/pipes.module';
import { RouterService } from '@shared/services/router.service';
import { ToolsService } from '@shared/services/tools.service';

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
})
export class CardComponent {
	@Input() type: string = 'portfolio';
	@Input() size: string = 'md';
	@Input() element: any = {};
	@Input() square: boolean = false;
	@Input() showTags: boolean = false;

	constructor(private routerService: RouterService) {}

	openPage() {
		this.routerService.open(this.type !== 'blog' ? this.type + '/' + this.element.slug : this.element.slug);
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [CardComponent],
	exports: [CardComponent],
	imports: [CommonModule, TranslateModule, PipesModule],
})
export class CardModule {}

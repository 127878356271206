<div class="menu-container" [class.open]="menuOpen">
	<div class="menu">
		<div class="element" *ngFor="let page of list()">
			<ng-container *ngTemplateOutlet="menuElement; context: { el: page }"></ng-container>
			<div class="sub-elements" *ngIf="list(page.path)?.length > 0">
				<ng-container *ngFor="let subpage of list(page.path)">
					<ng-container *ngTemplateOutlet="menuElement; context: { el: subpage }"></ng-container>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="menu-button" (click)="menuOpen = !menuOpen">
		<img alt="Menù button" [src]="menuOpen ? 'assets/icons/close-white.svg' : 'assets/icons/bars-white.svg'" />
	</div>
	<img
		*ngIf="environment.whatsapp.active"
		alt="Whatsapp button"
		class="whatsapp-button"
		(click)="whatsappService.open()"
		src="/assets/icons/whatsapp-white.svg" />
</div>

<!-- MENU ELEMENT -->
<ng-template #menuElement let-el="el">
	<a [class.active]="active(el.url)" (click)="menuOpen = false" [class.disabled]="el.disabled" [href]="el?.path">
		<img *ngIf="el.icon" class="icon" src="{{ el.icon }}" />
		<img *ngIf="el.iconActive" class="icon active" src="{{ el.iconActive }}" />
		<span>{{ el.name | titlecase }}</span>
	</a>
</ng-template>
<!-- END MENU ELEMENT -->

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
	providedIn: 'root',
})
export class RecaptchaService {
	constructor(private recaptchaV3Service: ReCaptchaV3Service) {}

	async getToken() {
		return await this.recaptchaV3Service.execute('importantAction').toPromise();
	}

	async prepareBody(body: any) {
		if (environment.recaptcha.siteKey) {
			body.recaptcha = await this.getToken();
		}
		return body;
	}
}

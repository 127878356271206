<div
	class="card-element"
	[ngClass]="'card-' + type + ' ' + size"
	[class.active]="element.active"
	[class.square]="square"
	(click)="openPage()">
	<div class="info-top" *ngIf="element?.categories && type !== 'portfolio'">
		<div class="category-container" *ngIf="element?.categories">
			<div class="category" *ngFor="let category of element?.categories">{{ category.name }}</div>
		</div>
		<div class="date" *ngIf="element?.date">{{ element.date | date: 'dd.MM.y' }}</div>
	</div>

	<!-- IMAGE -->
	<div class="background" *ngIf="element?.image">
		<img
			[alt]="element.name || 'image'"
			[src]="element.image | cdn: (square ? 280 : 700)"
			[ngClass]="element.imageFit || ''" />

		<div class="tags-container" *ngIf="element?.tags && showTags">
			<span class="tag" *ngFor="let tag of element?.tags">#{{ tag }}</span>
		</div>
	</div>
	<!-- END IMAGE -->

	<!-- INFO BOTTOM -->
	<div class="info-bottom" *ngIf="element?.name">
		<div class="info">
			<div class="title">{{ element.name }}</div>
			<div class="detail" *ngIf="element?.customer">Sviluppato per: {{ element.customer }}</div>
			<div class="description" *ngIf="element?.description">{{ element.description }}</div>
		</div>

		<!-- TECHNOLOGIES -->
		<div class="technologies" *ngIf="element?.technologies?.length > 0">
			<img [alt]="tech?.name" *ngFor="let tech of element.technologies" [src]="tech.icon" loading="lazy" />
		</div>
		<!-- END TECHNOLOGIES -->
	</div>
	<!-- END INFO BOTTOM -->
</div>

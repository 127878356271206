import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { HeaderPageModule } from '@components/header-page/header-page.component';

/*================================================================
# MODULES
================================================================*/
import { TranslateModule } from '@ngx-translate/core';
import { SectionModule } from '@components/section/section.component';
import { SummaryModule } from '@components/summary/summary.component';
import { ApiService } from '@shared/services/api.service';
import { RouterService } from '@shared/services/router.service';

@Component({
	selector: 'app-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
	@Input() inModal: boolean = false;

	page: any;
	background = 'assets/img/background.jpg';
	breadcrumbs: any = [{ name: 'Privacy policy', path: 'privacy-policy' }];

	constructor(
		private apiService: ApiService,
		private routerService: RouterService
	) {}

	/*================================================================
	# INIT
	================================================================*/
	async ngOnInit() {
		this.page = await this.apiService.post('page/page/privacy-policy');
		if (this.page?.background) this.background = this.page?.background;

		// Set SEO
		this.routerService.setSEO({
			title: this.page.name,
			description: this.page.description,
			date: this.page.date,
			type: 'article',
		});
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [PrivacyPolicyComponent],
	imports: [CommonModule, TranslateModule, HeaderPageModule, SectionModule, SummaryModule],
})
export class PrivacyPolicyModule {}

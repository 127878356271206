import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/*================================================================
# PAGES
================================================================*/
import { App404Component } from '@shared/components/404/404.component';
import { App403Component } from '@shared/components/403/403.component';
import { HomeComponent } from '@pages/home/home.component';
import { BlogComponent } from '@pages/blog/blog.component';
import { ChiSonoComponent } from '@pages/chi-sono/chi-sono.component';
import { ContattiComponent } from '@pages/contatti/contatti.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { PrivacyPolicyComponent } from '@pages/privacy-policy/privacy-policy.component';
import { InfoComponent } from '@pages/info/info.component';
import { ServiziComponent } from './pages/servizi/servizi.component';
import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';
import { LandingComponent } from '@pages/landing/landing.component';

/*================================================================
# ROUTES
================================================================*/
const routes: Routes = [
	/*================================================================
	# HOME
	================================================================*/
	{
		path: '',
		component: HomeComponent,
		data: { name: 'Alchimia Tecnologica' },
		loadChildren: () => import('./pages/home/home.component').then(m => m.HomeModule),
	},

	/*================================================================
	# REDIRECT
	================================================================*/
	{ path: 'piani', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'manutenzione-sito-web', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'portfolio/ixellion', redirectTo: 'portfolio-web', pathMatch: 'full' },
	{ path: 'affidaci-il-tuo-sito', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'seo-expert', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'web-expert', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'il-magic-pack', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'project', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'soluzioni-software-per-lazienda', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'sviluppo-software', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'tech-news', redirectTo: 'blog', pathMatch: 'full' },
	{ path: 'tech-blog', redirectTo: 'blog', pathMatch: 'full' },
	{ path: 'di-maio-matera-e-la-puglia-la-presunta-gaffe-e-una-bufala', redirectTo: 'blog', pathMatch: 'full' },
	{ path: 'car-hacking-at-def-con-26', redirectTo: 'blog', pathMatch: 'full' },
	{ path: 'signal-generator-uses-fpga', redirectTo: 'blog', pathMatch: 'full' },
	{ path: 'progettazione-software', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'piani-manutenzione', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'piani-gestione-contenuti', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'la-giusta-idea', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'gestione-dei-contenuti', redirectTo: 'servizi', pathMatch: 'full' },
	{ path: 'help', redirectTo: 'contatti/problema-tecnico', pathMatch: 'full' },
	{ path: 'partner', redirectTo: 'contatti/collaborazione', pathMatch: 'full' },
	{ path: 'attivazione-servizio', redirectTo: 'contatti/info', pathMatch: 'full' },
	{ path: 'san-valentino', redirectTo: 'portfolio-web', pathMatch: 'full' },
	{ path: 'privacy', redirectTo: 'privacy-policy', pathMatch: 'full' },
	{ path: 'feed', redirectTo: 'blog', pathMatch: 'full' },
	{
		path: 'ecco-come-richiedere-un-indennizzo-a-iliad-se-la-portabilita-del-numero-subisce-ritardi',
		redirectTo: 'blog',
		pathMatch: 'full',
	},
	{
		path: 'updating-a-1999-saab-with-an-arduino',
		redirectTo: 'blog',
		pathMatch: 'full',
	},

	/*================================================================
	# PAGES
	================================================================*/
	{
		path: 'portfolio/:id',
		component: PortfolioComponent,
		data: { name: 'Portfolio' },
		loadChildren: () => import('./pages/portfolio/portfolio.component').then(m => m.PortfolioModule),
	},
	{
		path: 'portfolio',
		redirectTo: 'portfolio-web',
		data: { name: 'Portfolio', parent: '' },
	},
	{
		path: 'portfolio-web',
		component: PortfolioComponent,
		data: { name: 'Portfolio Web', parent: 'portfolio', type: 'web' },
		loadChildren: () => import('./pages/portfolio/portfolio.component').then(m => m.PortfolioModule),
	},
	{
		path: 'portfolio-software',
		component: PortfolioComponent,
		data: { name: 'Portfolio Software', parent: 'portfolio', type: 'software' },
		loadChildren: () => import('./pages/portfolio/portfolio.component').then(m => m.PortfolioModule),
	},
	{
		path: 'portfolio-app-plugins',
		component: PortfolioComponent,
		data: { name: 'Portfolio App/Plugins', parent: 'portfolio', type: 'plugin' },
		loadChildren: () => import('./pages/portfolio/portfolio.component').then(m => m.PortfolioModule),
	},
	{
		path: 'servizi',
		component: ServiziComponent,
		data: { name: 'Servizi', parent: '' },
		loadChildren: () => import('./pages/servizi/servizi.component').then(m => m.ServiziModule),
	},
	{
		path: 'chi-sono',
		component: ChiSonoComponent,
		data: { name: 'Chi sono', parent: '' },
		loadChildren: () => import('./pages/chi-sono/chi-sono.component').then(m => m.ChiSonoModule),
	},
	{
		path: 'privacy-policy',
		component: PrivacyPolicyComponent,
		data: { name: 'Privacy policy' },
		loadChildren: () => import('./pages/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyModule),
	},
	{
		path: 'contatti',
		component: ContattiComponent,
		data: { name: 'Contatti', parent: '' },
		loadChildren: () => import('./pages/contatti/contatti.component').then(m => m.ContattiModule),
	},
	{
		path: 'contatti/:sectionID',
		component: ContattiComponent,
		data: { name: 'Contatti' },
		loadChildren: () => import('./pages/contatti/contatti.component').then(m => m.ContattiModule),
	},
	{
		path: 'blog',
		component: BlogComponent,
		data: { name: 'Blog', parent: '' },
		loadChildren: () => import('./pages/blog/blog.component').then(m => m.BlogModule),
	},
	{
		path: 'categoria/:categoryID',
		component: BlogComponent,
		data: { name: 'Blog' },
		loadChildren: () => import('./pages/blog/blog.component').then(m => m.BlogModule),
	},
	{
		path: 'tag/:tagID',
		component: BlogComponent,
		data: { name: 'Blog' },
		loadChildren: () => import('./pages/blog/blog.component').then(m => m.BlogModule),
	},
	{
		path: 'landing/:landingID',
		component: LandingComponent,
		data: { name: '' },
		loadChildren: () => import('./pages/landing/landing.component').then(m => m.LandingModule),
	},
	{
		path: 'landing',
		redirectTo: '',
		pathMatch: 'full',
	},

	/*================================================================
	# MODAL
	================================================================*/
	{
		path: 'info',
		component: InfoComponent,
		data: { name: 'Info' },
		loadChildren: () => import('./pages/info/info.component').then(m => m.InfoModule),
	},
	{
		path: 'image-preview',
		component: ImagePreviewComponent,
		data: { name: 'Image preview' },
		loadChildren: () =>
			import('./components/image-preview/image-preview.component').then(m => m.ImagePreviewModule),
	},

	/*================================================================
	# 403
	================================================================*/
	{
		//403
		path: '403',
		data: { name: '403' },
		component: App403Component,
	},

	/*================================================================
	# 404
	================================================================*/
	{
		//404
		path: '404',
		data: { name: '404' },
		component: App404Component,
	},
	{
		path: ':id',
		component: BlogComponent,
		data: { name: 'Blog' },
		loadChildren: () => import('./pages/blog/blog.component').then(m => m.BlogModule),
	},

	{
		//404
		path: '**',
		data: { name: '404' },
		component: App404Component,
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}

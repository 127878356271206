import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';

/*================================================================
# MODULES
================================================================*/
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'app-image-preview',
	templateUrl: './image-preview.component.html',
	styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent {
	@Input() image: string = '';
	@Input() title: string = 'Anteprima immagine';
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [ImagePreviewComponent],
	imports: [CommonModule, TranslateModule],
	exports: [ImagePreviewComponent],
})
export class ImagePreviewModule {}

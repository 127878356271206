import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { register } from 'swiper/element/bundle';
import { CardModule } from '@components/card/card.component';
register();

@Component({
	selector: 'app-portfolio-related',
	templateUrl: './portfolio-related.component.html',
	styleUrls: ['./portfolio-related.component.scss'],
})
export class PortfolioRelatedComponent {
	@Input() related: any;
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [PortfolioRelatedComponent],
	exports: [PortfolioRelatedComponent],
	imports: [CommonModule, TranslateModule, CardModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PortfolioRelatedModule {}

import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { WhatsappService } from '@services/whatsapp.service';
import { DirectivesModule } from '@shared/directives/directives.module';

/*================================================================
# SERVICES
================================================================*/
import { RouterService } from '@shared/services/router.service';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
	menuOpen = false;
	environment = environment;

	constructor(
		public routerService: RouterService,
		public whatsappService: WhatsappService
	) {}

	list(parent: string = '') {
		const el = this.routerService.list(parent);
		return el;
	}

	active(url: string) {
		const actual = this.routerService.actual();
		const regex = new RegExp(`^${url}$`);
		return regex.test(actual);
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [MenuComponent],
	imports: [CommonModule, TranslateModule, DirectivesModule],
	exports: [MenuComponent],
})
export class MenuModule {}

<div
	class="page-container page-container-bg"
	[ngStyle]="{ backgroundImage: 'url(assets/img/background6.jpg)' }"
	*ngIf="!loading">
	<!-- HEADER -->
	<app-header-page
		(eventCategoryClick)="openCategory($event)"
		mode="light"
		[showImage]="true"
		[element]="page"
		[breadcrumb]="breadcrumb"></app-header-page>
	<!-- END HEADER -->

	<!-- BLOG GENERAL -->
	<ng-container *ngIf="!page?.id">
		<ng-container *ngTemplateOutlet="blog; context: { page }"></ng-container>
	</ng-container>
	<!-- END BLOG GENERAL -->

	<!-- SINGLE PAGE -->
	<ng-container *ngIf="page?.id">
		<ng-container *ngTemplateOutlet="blogSingle; context: { page }"></ng-container>
	</ng-container>
	<!-- END SINGLE PAGE -->

	<!-- SECTION CONTATTAMI -->
	<div class="contattami-container">
		<app-section-contattami></app-section-contattami>
	</div>
	<!-- END SECTION CONTATTAMI -->
</div>

<!--===============================================================
# TEMPLATE: BLOG GENERAL
================================================================-->
<ng-template #blog let-page="page">
	<div class="wrapper" *ngIf="page?.elements.length > 0">
		<div class="category-title" *ngIf="this.actualCategory">
			<h4>
				Categoria:
				<div (click)="back()">
					<img src="assets/icons/close-white.svg" />
					{{ actualCategory?.name }}
				</div>
			</h4>
		</div>
		<div class="tag-title" *ngIf="this.actualTag">
			<h4>
				Tag:
				<div (click)="back()">
					<img src="assets/icons/close-white.svg" />
					{{ actualTag }}
				</div>
			</h4>
		</div>

		<div class="blog-container">
			<div class="col-sx">
				<!--===============================================================
				# CARD
				================================================================-->
				<app-card
					type="blog"
					[square]="true"
					[element]="el"
					[showTags]="true"
					*ngFor="let el of page?.elements; trackBy: trackByFn"></app-card>

				<!--===============================================================
				# SKELETON
				================================================================-->
				<ng-container *ngIf="loadingMorePosts">
					<div class="skeleton-element active fadeIn" *ngFor="let el of [].constructor(9)">
						<app-skeleton height="30px"></app-skeleton>
						<app-skeleton height="200px"></app-skeleton>
						<app-skeleton height="95px"></app-skeleton>
					</div>
				</ng-container>
			</div>
			<div class="col-dx" *ngIf="!actualCategory && !actualTag">
				<app-me [links]="false" [animation]="false"></app-me>

				<div class="categories-container">
					<h4>Categorie</h4>
					<div class="categories">
						<a
							[href]="'categoria/' + category.id"
							class="category-element"
							*ngFor="let category of page?.allCategories">
							{{ category.name }}
						</a>
					</div>
				</div>

				<div class="tags-container">
					<h4>Tags</h4>
					<div class="tags">
						<a [href]="'tag/' + tag" class="category-element" *ngFor="let tag of page?.allTags">
							#{{ tag }}
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="wrapper" *ngIf="!page?.elements?.length">
		<div class="category-title text-center">
			<h4 class="pb-3">Il filtro selezionato non ha prodotto risultati</h4>
			<button type="button px-5" (click)="back()">Torna al blog</button>
		</div>
	</div>
</ng-template>

<!--===============================================================
# TEMPLATE: SINGLE PAGE
================================================================-->
<ng-template #blogSingle let-page="page">
	<article>
		<!-- SUMMARY -->
		<app-summary *ngIf="!page?.hideSummary" [page]="page"></app-summary>
		<!-- END SUMMARY -->

		<!-- SECTIONS -->
		<app-section [section]="section" *ngFor="let section of page?.sections"></app-section>
		<!-- END SECTIONS -->
	</article>

	<!-- RELATED -->
	<section id="related" class="related-container" *ngIf="page?.related?.length > 0">
		<app-blog-related [related]="page?.related"></app-blog-related>
	</section>
	<!-- END RELATED -->
</ng-template>

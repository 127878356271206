<div class="breadcrumb-container" [ngClass]="mode">
	<div class="element">
		<a href="/">
			<img alt="Home" src="assets/icons/home-{{ mode === 'dark' ? 'dark' : 'white' }}.svg" />
		</a>
	</div>

	<div class="element" *ngFor="let el of elements">
		<a [href]="el?.path">
			<span *ngIf="el?.name">{{ el?.name }}</span>
		</a>
	</div>
</div>

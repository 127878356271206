import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { CardModule } from '@components/card/card.component';
import { BlogService } from '@pages/blog/services/blog.service';
import { RouterService } from '@shared/services/router.service';

@Component({
	selector: 'app-section-blog',
	templateUrl: './section-blog.component.html',
	styleUrls: ['./section-blog.component.scss'],
})
export class SectionBlogComponent implements OnInit {
	posts: any = [];

	constructor(
		private blogService: BlogService,
		public routerService: RouterService
	) {}

	ngOnInit(): void {
		this.loadServices();
	}

	async loadServices() {
		const posts: any = await this.blogService.getPosts({ page: 1, page_size: 4 });
		this.posts = posts?.elements || [];
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [SectionBlogComponent],
	imports: [CommonModule, CardModule],
	exports: [SectionBlogComponent],
})
export class SectionBlogModule {}

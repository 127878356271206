import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterService } from '@shared/services/router.service';

@Component({
	selector: 'app-box',
	templateUrl: './box.component.html',
	styleUrls: ['./box.component.scss'],
})
export class BoxComponent {
	@Input() element: any;
	@Input() height: string = 'auto';
	@Input() width: string = 'auto';

	@Output() buttonClick = new EventEmitter();

	active: boolean = false;

	constructor(public routerService: RouterService) {}

	buttonClicked(event: any, id: string) {
		this.buttonClick.emit({ id, event });
		event.stopPropagation();
	}

	toggleBox() {
		if (this.element?.preview && this.element?.detail) this.active = !this.active;
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [BoxComponent],
	exports: [BoxComponent],
	imports: [CommonModule, TranslateModule],
})
export class BoxModule {}

import { Injectable } from '@angular/core';
import { ApiService } from '@shared/services/api.service';

@Injectable({
	providedIn: 'root',
})
export class PortfolioService {
	constructor(private apiService: ApiService) {}

	/*================================================================
	# GET POSTS
	================================================================*/
	async getPortfolio(id: string, preview: boolean = false) {
		const body = { preview };
		return await this.apiService.post('portfolio/portfolio/' + id, body);
	}

	/*================================================================
	# GET CATEGORIES
	================================================================*/
	async getTypologies(id: string = 'web', ids: string[] = []) {
		const body = ids.length > 0 ? { ids } : null;
		return await this.apiService.post('portfolio/typologies/' + id, body);
	}
}

import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { HeaderPageModule } from '@components/header-page/header-page.component';

/*================================================================
# MODULES
================================================================*/
import { TranslateModule } from '@ngx-translate/core';
import { SectionModule } from '@components/section/section.component';
import { SummaryModule } from '@components/summary/summary.component';
import { RouterService } from '@shared/services/router.service';
import { WhatsappService } from '@services/whatsapp.service';
import { SectionContattamiModule } from '@components/section-contattami/section-contattami.component';
import { ApiService } from '@shared/services/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
	page: any;
	background = 'assets/img/background.jpg';
	breadcrumbs: any = [];

	constructor(
		public routerService: RouterService,
		public whatsappService: WhatsappService,
		private apiService: ApiService,
		private route: ActivatedRoute
	) {}

	/*================================================================
	# INIT
	================================================================*/
	async ngOnInit() {
		const id = this.route.snapshot.paramMap.get('landingID') || null;
		if (!id) return;

		try {
			const page = await this.apiService.get('landing/landing/' + id);
			this.page = page;
		} catch (err) {
			this.routerService.open('404');
			return;
		}

		if (this.page?.background) this.background = this.page?.background;

		// Breadcrumb
		this.breadcrumbs = [{ name: this.page.name, path: this.page.slug }];

		// Set SEO
		this.routerService.setSEO({
			title: this.page.name,
			description: this.page.description,
			date: this.page.date,
			type: 'article',
		});
	}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [LandingComponent],
	imports: [CommonModule, TranslateModule, HeaderPageModule, SectionModule, SummaryModule, SectionContattamiModule],
})
export class LandingModule {}

import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'keepHtml', pure: false })
export class KeepHtmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(content: any) {
		content = content
			.replace(/<link/g, '&lt;link')
			.replace(/<script/g, '&lt;script')
			.replace(/<\/link>/g, '&lt;/link>')
			.replace(/<\/script>/g, '&lt;/script>');
		// .replace(/<code>/g, '<code class="language-*">')
		// .replace(/<\/code>/g, '</code>');

		content = this.sanitizer.bypassSecurityTrustHtml(content);
		return content;
	}
}

export interface Alert {
	id?: string;
	title?: string;
	overlay?: boolean;
	dismissable?: boolean;
	buttons?: AlertButton[];
}

export interface AlertButton {
	id?: string;
	name: string;
	dismiss?: boolean;
}

export const initialAlert: Alert = {
	id: '',
	title: '',
	overlay: true,
	dismissable: false,
	buttons: [{ id: 'ok', name: 'Ok', dismiss: true }],
};

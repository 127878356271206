import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { Location } from '@angular/common';
import { LoaderService } from './loader.service';

import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class RouterService {
	routerMap: any;

	constructor(
		private router: Router,
		private titleService: Title,
		private metaService: Meta,
		private location: Location,
		private loaderService: LoaderService,
		@Inject(DOCUMENT) private document: Document
	) {
		this.routerMap = this.router.config;
		this.setSEO();
	}

	/*================================================================
	# GET TITLE
	================================================================*/
	getTitle() {
		let title = '';

		let path = this.location.path().substring(1);
		if (path.includes('#')) {
			path = path.split('#')[0];
		}

		const actualPath = this.get(path);
		if (actualPath) {
			title = actualPath?.data?.name;
		}
		return title;
	}

	/*================================================================
	# LIST MENU
	================================================================*/
	list(parent: string = '/') {
		const menuTemp: any = this.routerMap.filter((x: any) => x?.data?.parent === parent);
		menuTemp.forEach((el: any) => {
			el.url = '/' + el?.path;
			el.name = el?.data?.name ? el.data.name : this.getName(el.path);
			el.icon = el?.data?.icon;
			el.disabled = el?.data?.disabled;
			el.iconActive = el?.data?.iconActive;
		});
		return menuTemp;
	}

	/*================================================================
	# GET ELEMENT BY PATH
	================================================================*/
	get(path: string = '') {
		path = path || this.actual().replace('/', '');
		const el = this.routerMap.filter((menu: any) => menu.path === path);
		return el.length > 0 ? el[0] : false;
	}

	/*================================================================
	# GET NAME FROM PATH
	================================================================*/
	getName(path: string = '') {
		if (!path) {
			return '';
		}
		const arr = path.split('/');
		return arr[arr.length - 1];
	}

	/*================================================================
	# GET DOMAIN
	================================================================*/
	domain() {
		return 'https://' + this.document.location.hostname;
	}

	/*================================================================
	# GET ACTUAL URL
	================================================================*/
	actual() {
		return this.router.url;
	}

	/*================================================================
	# OPEN PAGE
	================================================================*/
	open(url: string, event?: any) {
		this.loaderService.ripple(true, event);

		setTimeout(() => {
			let actualLink = this.routerMap.filter((item: any) => item.url === url || item.path === url);
			actualLink = actualLink ? actualLink[0] : false;
			if (actualLink?.disabled) {
				return;
			} else if (actualLink?.data?.sidebar === undefined) {
				this.setSEO({ title: actualLink?.data?.name });
				this.router.navigateByUrl('#', { skipLocationChange: true }).then(() => this.router.navigate([url]));
			}
		}, 500);
	}

	/*================================================================
	# SET SEO
	================================================================*/
	async setSEO(info: any = {}) {
		if (!info?.title) {
			let path = this.location.path().substring(1);
			if (path.includes('#')) path = path.split('#')[0];
			const actualPath = this.get(path);
			if (actualPath) info.title = actualPath?.data?.name;
		}

		info.title = environment.app.name + (info.title ? ' - ' + info.title : '');
		this.titleService.setTitle(info?.title);

		const arrTags = [
			{
				type: 'name',
				key: 'robot',
				content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1',
			},
			{ type: 'name', key: 'description' },
			{ type: 'name', key: 'author', content: 'Arkas' },
			{ type: 'name', key: 'date' },
			{ type: 'property', key: 'article:publisher', content: 'Arkas' },
			{ type: 'property', key: 'article:published_time' },
			{ type: 'property', key: 'og:locale', content: 'it_IT' },
			{ type: 'property', key: 'og:site_name', content: environment.app.name },
			{ type: 'property', key: 'og:title' },
			{ type: 'property', key: 'og:type' },
			{ type: 'property', key: 'og:image' },
			{ type: 'property', key: 'og:url', content: this.domain() + this.actual() },
			{ type: 'property', key: 'og:description' },
			{ type: 'itemprop', key: 'name' },
			{ type: 'itemprop', key: 'description' },
			{ type: 'itemprop', key: 'image' },
			{ type: 'name', key: 'twitter:title' },
			{ type: 'name', key: 'twitter:description' },
			{ type: 'name', key: 'twitter:image' },
		];
		arrTags.forEach(tag => {
			let found = 0;
			this.metaService.removeTag(`${tag.type}="${tag.key}"`);

			if (info) {
				Object.keys(info).forEach((key: any) => {
					if (key === tag.key || 'og:' + key === tag.key || 'twitter:' + key === tag.key) {
						if ((info as any)[key]) {
							this.metaService.addTag(
								tag.type === 'property'
									? { property: tag.key, content: (info as any)[key] }
									: tag.type === 'name'
										? { name: tag.key, content: (info as any)[key] }
										: { itemprop: tag.key, content: (info as any)[key] }
							);
							found += 1;
						}
					}
				});
			}

			if (!found && tag.content) {
				this.metaService.addTag(
					tag.type === 'property'
						? { property: tag.key, content: tag.content }
						: tag.type === 'name'
							? { name: tag.key, content: tag.content }
							: { itemprop: tag.key, content: tag.content }
				);
			}
		});

		// Add canonical link
		const head = this.document.getElementsByTagName('head')[0];
		var element = this.document.querySelector(`link[rel='canonical']`) || null;
		if (element == null) {
			element = this.document.createElement('link') as HTMLLinkElement;
			head.appendChild(element);
		}
		element.setAttribute('rel', 'canonical');
		element.setAttribute('href', this.domain() + this.actual());
	}
}

import { Injectable } from '@angular/core';
import { ToolsService } from '@shared/services/tools.service';
import { environment } from '@env/environment';
import packageInfo from '@pak/package';

@Injectable({
	providedIn: 'root',
})
export class TokenService {
	localStorageName = packageInfo.name + '-settings';

	constructor(private toolsService: ToolsService) {}

	/*================================================================
	# GET INFO
	================================================================*/
	getInfo() {
		const token = null;
		// const token = localStorage.getItem(this.localStorageName);
		if (!token) {
			return null;
		}

		try {
			let obj: any = token;
			if (environment.api.tokenCrypt) {
				obj = this.toolsService.cripto(token, 1);
			}
			obj = JSON.parse(obj);
			const limit = this.toolsService.timestamp();
			const nextExp = this.toolsService.timeFromUTC(obj.token.expiration_date);
			if (nextExp < limit) {
				// Expired
				return false;
			}
			return obj;
		} catch (e) {
			return false;
		}
	}

	/*================================================================
	# SET TOKEN
	================================================================*/
	setToken(token: string = '') {
		// if (token.length === 0) {
		// 	localStorage.removeItem(this.localStorageName);
		// } else {
		// 	if (environment.api.tokenCrypt) {
		// 		token = this.toolsService.cripto(token);
		// 	}
		// 	localStorage.setItem(this.localStorageName, token);
		// }
	}

	/*================================================================
	# INTERCEPTOR
	================================================================*/
	checkToken(path: string = '') {
		if (!environment.api.checkToken) return true;

		// Check path if exists
		if (path) {
			const excludedUrls: string[] = ['login', 'token', 'reset', 'reset-link', 'email/confirm'];
			if (excludedUrls.some(excludedUrl => path.includes(excludedUrl))) {
				return true;
			}
		}

		// Get actual token in local storage
		const token = this.get('token.token');
		if (!token) {
			return token;
		}
		return true;
	}

	/*================================================================
	# GET VALUE
	================================================================*/
	get(key: string) {
		// Get all settings
		const settings = this.getInfo();
		if (!key) {
			return settings;
		}
		let temp = settings;
		const fields = key.split('.');
		if (fields?.length > 0) {
			fields?.forEach(field => {
				if (temp) {
					temp = temp[field];
				} else {
					return null;
				}
			});
		}
		return temp;
	}

	/*================================================================
	# SET VALUE
	================================================================*/
	set(key: string, value?: string) {
		// Get all settings
		const settings = this.getInfo();
		if (!settings) {
			return null;
		}

		if (value === undefined) {
			// Show value
			return this.get(key);
		} else if (value.length === 0) {
			// Remove value
			delete settings[key];
			return true;
		} else {
			// Edit value
			settings[key] = value;
			this.setToken(JSON.stringify(settings));
			return true;
		}
	}

	/*================================================================
	# CHECK EXPIRING
	================================================================*/
	isExpiredIn(min = 0) {
		const info = this.getInfo();
		if (!info) {
			return true;
		}
		const limit = this.toolsService.timestamp() + min * 60;
		const nextExp = this.toolsService.timeFromUTC(info.token.expiration_date);
		return nextExp < limit;
	}

	/*================================================================
	# HAS ROLE
	================================================================*/
	hasRole(roles: string[]) {
		if (!roles) {
			return true;
		}
		const userRole = this.get('user.role');
		if (!userRole) {
			return false;
		}
		return roles.includes(userRole);
	}

	/*================================================================
	# GET ACL
	================================================================*/
	acl(action: string) {
		const info = action.split(':');
		const infoFunct = info[0];
		const infoAcl = info[1];

		const acl = this.get('user.acl.' + infoFunct);
		if (!acl) {
			return false;
		}
		if (!infoAcl) {
			// Return functionality acls
			return acl;
		} else {
			return acl.includes(infoAcl);
		}
	}
}

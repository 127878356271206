import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root',
})
export class ServiziService {
	services = [
		{
			id: 'web-site',
			title: 'Siti Web',
			preview: {
				image: 'assets/img/website.svg',
			},
			detail: {
				text: 'Comunicare adeguatamente il proprio messaggio è la chiave per trasformare un visitatore in un cliente affezionato.<br><br>Un sito web veloce e professionale è un biglietto da visita perfetto per la propria attività.',
				buttons: [{ id: 'info-web-site', text: 'Maggiori informazioni' }],
			},
			data: {
				image: 'assets/img/website.svg',
				elements: [
					{
						title: `Perché un sito web?`,
						preview: {
							text: `Internet sta diventando sempre più alla portata di tutti e ogni attività che si rispetti necessità di essere sul web. Il mio scopo è quello di privilegiare i punti di forza della tua attività per consentire ai tuoi clienti di scegliere te, invece che la tua concorrenza.`,
						},
					},
					{
						title: `Dai un'occhiata al Portfolio`,
						preview: {
							text: `Ho già sviluppato diverse soluzioni per i miei partner e per i miei clienti. Quando possibile, cerco sempre di documentare al meglio ogni nuovo sviluppo.`,
							buttons: [
								{
									id: 'portfolio-web-site',
									text: 'Vai al portfolio',
								},
							],
						},
					},
					{
						title: 'Vuoi un sito web?',
						preview: {
							text: 'Lo sviluppo dei siti web è la cosa che stimola di più la mia fantasia, contattami e aiutami a realizzare il tuo.',
							buttons: [
								{
									id: 'contatti-info',
									text: 'Contattami',
								},
								{
									id: 'whatsapp',
									text: 'Scrivimi su WhatsApp <img class="ms-2" src="/assets/icons/whatsapp-white.svg" />',
									class: 'success',
									hide: !environment.whatsapp.active,
								},
							],
						},
					},
				],
			},
		},
		{
			id: 'web-app',
			title: 'Software - Web App',
			preview: {
				image: 'assets/img/webapp.svg',
			},
			detail: {
				text: 'Strumenti pensati per il lavoro di tutti i giorni, che hanno il potere di rendere semplici anche le operazioni più complesse.<br><br>Ottimizzare il lavoro riduce gli errori e ti consente di risparmiare tempo.',
				buttons: [{ id: 'info-web-app', text: 'Maggiori informazioni' }],
			},
			data: {
				image: 'assets/img/webapp.svg',
				elements: [
					{
						title: `Che cos'è una web app?`,
						preview: {
							text: `Una web app, o "applicazione web", è un'applicazione software che utilizza tecnologie web per essere accessibile e utilizzata attraverso un browser web.<br><br>A differenza delle applicazioni tradizionali che devono essere scaricate e installate su un dispositivo, le web app possono essere eseguite direttamente attraverso un browser web su qualsiasi dispositivo con accesso a Internet.`,
						},
					},
					{
						title: `Non serve per forza il browser`,
						preview: {
							text: `Sono specializzato anche nello sviluppo di applicazioni desktop che possono essere eseguite come applicazioni indipendenti, senza dover aprire un browser separato.<br><br>Questo è particolarmente utile quando si desidera distribuire un'applicazione che richiede l'accesso a risorse di sistema o funzionalità avanzate del sistema operativo.`,
							buttons: [
								{
									id: 'portfolio-web-app',
									text: `Dai un'occhiata alle web app che ho realizzato`,
								},
							],
						},
					},
					{
						title: 'Hai bisogno di un software?',
						preview: {
							text: 'Ogni software nasce e si plasma attorno alle esigenze di ognuno di noi. Aiutami ad individuare i tuoi obiettivi e le funzionalità che ti servono.',
							buttons: [
								{
									id: 'contatti-info',
									text: 'Contattami',
								},
								{
									id: 'whatsapp',
									text: 'Scrivimi su WhatsApp <img class="ms-2" src="/assets/icons/whatsapp-white.svg" />',
									class: 'success',
									hide: !environment.whatsapp.active,
								},
							],
						},
					},
				],
			},
		},
		{
			id: 'app',
			title: 'App',
			preview: {
				image: 'assets/img/app.svg',
			},
			detail: {
				text: 'La soluzione più utile e comoda che ti consente di lavorare in tutta sicurezza da ogni parte del mondo.<br><br>Avari a portata di mano la possibilità di effettuare operazioni (anche complesse), senza portarti dietro un pc.',
				buttons: [{ id: 'info-app', text: 'Maggiori informazioni' }],
			},
			data: {
				image: 'assets/img/app.svg',
				elements: [
					{
						title: 'Analisi e progettazione',
						preview: {
							text: `Ogni app è progettata per adattarsi perfettamente alle esigenze dell'utente.<br>Ogni sviluppo è cross-platform, in modo da garantire un'esperienza utente uniforme su diverse piattaforme come iOS, Android e web.`,
						},
					},
					{
						title: 'Ottimizzazione delle Prestazioni',
						preview: {
							text: `Ogni app deve essere veloce, reattiva e deve rendere l'esperienza utente fluida e non macchinosa.<br>Tutto questo è garantito dal fatto che scrivo il codice in modo pulito ed utilizzo sempre le ultime tecnologie disponibili sul mercato.`,
						},
					},
					{
						title: 'Funzionalità avanzate',
						preview: {
							text: `Implemento funzionalità avanzate come geolocalizzazione, notifiche push, integrazione di database e servizi esterni.`,
						},
					},
					{
						title: 'I lavori sviluppati',
						preview: {
							text: `Dai un'occhiata ai progetti a cui ho partecipato sino ad ora, ti accorgerai che mi piace mettermi alla prova e fornire soluzioni professionali ed accattivanti!`,
							buttons: [
								{
									id: 'portfolio-plugins',
									text: 'Vai al portfolio',
								},
							],
						},
					},
					{
						title: 'Sviluppo nuova app',
						preview: {
							text: `Hai un'idea in mente e vuoi realizzare la tua app? Contattami, così ne parliamo!`,
							buttons: [
								{
									id: 'contatti-info',
									text: 'Contattami',
								},
								{
									id: 'whatsapp',
									text: 'Scrivimi su WhatsApp <img class="ms-2" src="/assets/icons/whatsapp-white.svg" />',
									class: 'success',
									hide: !environment.whatsapp.active,
								},
							],
						},
					},
				],
			},
		},
		{
			id: 'plugins',
			title: 'Plugin e funzionalità',
			preview: {
				image: 'assets/img/plugins.svg',
			},
			detail: {
				text: `Sviluppare componenti aggiunti che estendono o aggiungono funzionalità a un sito web o ad un'applicazione già esistente.<br><br>In questo modo si può incrementare il valore di un progetto, senza necessariamente doverlo stravolgere.`,
				buttons: [{ id: 'info-plugins', text: 'Maggiori informazioni' }],
			},
			data: {
				image: 'assets/img/plugins.svg',
				elements: [
					{
						title: `Analisi delle esigenze`,
						preview: {
							text: `Prima di iniziare lo sviluppo, conduco un'analisi approfondita delle tue esigenze e obiettivi, assicurandomi di comprendere completamente ciò che vuoi ottenere con le nuove funzionalità.<br><br>Utilizzo un approccio creativo nella progettazione delle nuove funzionalità, cercando di offrire un valore tangibile così da ottenere un ottimo feedback agli utenti finali.`,
						},
					},
					{
						title: 'Sviluppo Agile',
						preview: {
							text: `Adotto metodologie di sviluppo agile per garantire una rapida implementazione delle nuove funzionalità, consentendoti di beneficiare delle ultime innovazioni nel minor tempo possibile.<br><br>Grazie a questo metodo, sarai sempre aggiornato sullo stato delle lavorazioni ogni volta che uno step dello sviluppo sarà completato.`,
						},
					},
					{
						title: `Dai un'occhiata al Portfolio`,
						preview: {
							text: `Ho già sviluppato diverse soluzioni per i miei partner e per i miei clienti. Quando possibile, cerco sempre di documentare al meglio ogni nuovo sviluppo.`,
							buttons: [
								{
									id: 'portfolio-plugins',
									text: 'Vai al portfolio',
								},
							],
						},
					},
					{
						title: 'Vuoi qualcosa di nuovo?',
						preview: {
							text: 'Se vuoi che mi occupi di sviluppare una nuova funzionalità su una tua piattaforma, sono tutti orecchi!',
							buttons: [
								{
									id: 'contatti-info',
									text: 'Contattami',
								},
								{
									id: 'whatsapp',
									text: 'Scrivimi su WhatsApp <img class="ms-2" src="/assets/icons/whatsapp-white.svg" />',
									class: 'success',
									hide: !environment.whatsapp.active,
								},
							],
						},
					},
				],
			},
		},
		{
			id: 'manutenzione',
			title: 'Manutenzione Siti Web',
			preview: {
				image: 'assets/img/manutenzione.svg',
			},
			detail: {
				text: 'I siti web obsoleti sono vulnerabili agli attacchi informatici. Mantenerli aggiornati è cruciale per proteggerli da eventuali vulnerabilità di sicurezza.<br><br>Le tecnologie web e i browser evolvono costantemente, aggiornare il sito assicura la compatibilità su tutti i dispositivi.',
				buttons: [{ id: 'info-manutenzione', text: 'Maggiori informazioni' }],
			},
			data: {
				image: 'assets/img/manutenzione.svg',
				elements: [
					{
						title: 'Hosting e dominio',
						preview: {
							text: `Per tutti i servizi che offro, mi occupo personalmente dell'hosting e della registrazione del dominio. Ogni anno effettuo regolarmente il rinnovo in modo da evitare fastidiose comunicazione all'utente finale.`,
						},
					},
					{
						title: 'Aggiornamento tema e plugin',
						preview: {
							text: `Se il tuo sito è stato realizzato in WordPress, mi occuperò di aggiornare costantemente sia il tema che i plugin installati. Questo, per quanto banale, ne garantisce l'incremento della sicurezza e delle prestazioni`,
						},
					},
					{
						title: 'Hai un sito web da mantenere?',
						preview: {
							text: 'Se non le competenze tecniche, o semplicemente non hai tempo da perdere dietro agli aggiornamenti o alle scadenze, ci pensarò io per te!',
							buttons: [
								{
									id: 'contatti-info',
									text: 'Contattami',
								},
								{
									id: 'whatsapp',
									text: 'Scrivimi su WhatsApp <img class="ms-2" src="/assets/icons/whatsapp-white.svg" />',
									class: 'success',
									hide: !environment.whatsapp.active,
								},
							],
						},
					},
					{
						title: 'Incremento delle funzionalità',
						preview: {
							text: `Se hai bisogno di aggiungere funzionalità al tuo sito, possiamo progettare insieme il modo in cui svilupparle.`,
							buttons: [
								{
									id: 'info-plugins',
									text: 'Sviluppo funzionalità',
								},
							],
						},
					},
				],
			},
		},
	];

	constructor() {}

	get(idService?: string): Servizio {
		const service = this.services.find(x => x.id === idService);
		if (!service) return {};
		return service;
	}

	list(): Servizio[] {
		return this.services;
	}
}

export interface Servizio {
	id?: string;
	title?: string;
	detail?: any;
	data?: any;
}

import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { MenuModule } from '@components/menu/menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterService } from '@shared/services/router.service';

@Component({
	selector: 'app-divider',
	templateUrl: './divider.component.html',
	styleUrls: ['./divider.component.scss'],
})
export class DividerComponent {
	@Input() hide: boolean = false;

	constructor(public routerService: RouterService) {}
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [DividerComponent],
	exports: [DividerComponent],
	imports: [CommonModule, TranslateModule, MenuModule],
})
export class DividerModule {}

import { ApiService } from '@shared/services/api.service';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class BlogService {
	constructor(private apiService: ApiService) {}

	/*================================================================
	# GET POSTS
	================================================================*/
	async getPosts(body?: any) {
		return await this.apiService.post('blog/blog', body);
	}

	async getPost(id: string, preview: boolean = false) {
		const body = { preview };
		return await this.apiService.post('blog/blog/' + id, body);
	}
}

<form [formGroup]="form" (ngSubmit)="sumbitForm()">
	<div class="form-row">
		<div class="field" [class.invalid]="form.controls['nome'].invalid && formSubmitted">
			<label>Nome [*]</label>
			<input type="text" class="form-control" formControlName="nome" />
		</div>
		<div class="field" [class.invalid]="form.controls['cognome'].invalid && formSubmitted">
			<label>Cognome [*]</label>
			<input type="text" class="form-control" formControlName="cognome" />
		</div>
	</div>

	<div class="form-row">
		<div class="field" [class.invalid]="form.controls['email'].invalid && formSubmitted">
			<label>Email a cui devo rispondere [*]</label>
			<input type="text" class="form-control" formControlName="email" />
		</div>
	</div>

	<div class="form-row">
		<div class="field" [class.invalid]="form.controls['telefono'].invalid && formSubmitted">
			<label>Numero di telefono</label>
			<input type="text" class="form-control" formControlName="telefono" />
		</div>
	</div>

	<div class="form-row">
		<div class="field" [class.invalid]="form.controls['testo'].invalid && formSubmitted">
			<label>Testo del messaggio [*]</label>
			<textarea class="form-control" formControlName="testo"></textarea>
		</div>
	</div>

	<div class="form-info">
		Si prega di visionare il trattamento dei dati a l'informativa sulla privacy a
		<span class="link" (click)="showPrivacy()">questo link</span>
		prima di accettare.
	</div>

	<hr />

	<div class="form-row">
		<div class="field" [class.invalid]="form.controls['privacy'].invalid && formSubmitted">
			<app-toggle
				[checked]="!!form.controls['privacy'].value"
				(eventChange)="form.controls['privacy'].setValue($event ? 'true' : '')">
				Confermo di aver letto ed accettato il trattamento dei dati e l'informativa sulla privacy (visionato
				poco sopra) [*].
			</app-toggle>
		</div>
	</div>

	<button class="w-100">Invia messaggio</button>
</form>

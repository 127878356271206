export interface Modal {
	id?: string;
	title?: string;
	fullscreen?: boolean;
	fullscreenBtn?: boolean;
	overlay?: boolean;
	resizable?: boolean;
	draggable?: boolean;
	dismissable?: boolean;
	closeButton?: boolean;
	header?: boolean;
	height?: string;
	width?: string;
	animation?: boolean;
	miniClose?: boolean;
	data?: any;
}

export const initialModal: Modal = {
	id: '',
	title: '',
	fullscreen: false,
	fullscreenBtn: false,
	overlay: true,
	resizable: false,
	draggable: false,
	dismissable: true,
	animation: true,
	closeButton: true,
	header: true,
	height: '0',
	width: '0',
	miniClose: false,
	data: null,
};

import { CommonModule } from '@angular/common';
import { Directive, Input, ElementRef, HostListener, Renderer2, NgModule } from '@angular/core';

@Directive({
	selector: '[tooltip]',
})
export class TooltipDirective {
	@Input('tooltip') tooltipTitle = '';
	@Input() tooltipDirection = 'top';
	@Input() delay = 0;
	tooltip: HTMLElement | any;
	offset = 10;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2
	) {}

	@HostListener('mouseenter') onMouseEnter() {
		if (!this.tooltip) {
			this.show();
		}
	}

	@HostListener('mouseleave') onMouseLeave() {
		if (this.tooltip) {
			this.hide();
		}
	}

	show() {
		if (!this.tooltipTitle) {
			return;
		}

		// FIX - Close other tooltip
		Array.from(document.getElementsByClassName('ng-tooltip-show')).forEach((el: any) => {
			this.renderer.removeChild(document.body, el);
		});

		this.create();
		this.setPosition();
		this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
	}

	hide() {
		this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
		setTimeout(() => {
			if (document.body && this.tooltip) {
				this.renderer.removeChild(document.body, this.tooltip);
				this.tooltip = null;
			}
		}, this.delay);
	}

	create() {
		this.tooltip = this.renderer.createElement('span');

		this.renderer.appendChild(
			this.tooltip,
			this.renderer.createText(this.tooltipTitle) // textNode
		);

		this.renderer.appendChild(document.body, this.tooltip);
		this.renderer.addClass(this.tooltip, 'ng-tooltip');
		this.renderer.addClass(this.tooltip, `ng-tooltip-${this.tooltipDirection}`);
		this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
		this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
		this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
		this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);
	}

	setPosition() {
		const hostPos = this.el.nativeElement.getBoundingClientRect();
		const tooltipPos = this.tooltip.getBoundingClientRect();
		const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

		let top;
		let left;

		if (this.tooltipDirection === 'top') {
			top = hostPos.top - tooltipPos.height - this.offset;
			left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
		}

		if (this.tooltipDirection === 'bottom') {
			top = hostPos.bottom + this.offset;
			left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
		}

		if (this.tooltipDirection === 'left') {
			top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
			left = hostPos.left - tooltipPos.width - this.offset;
		}

		if (this.tooltipDirection === 'right') {
			top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
			left = hostPos.right + this.offset;
		}

		this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
		this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
	}
}

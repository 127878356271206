import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { BreadcrumbModule } from '@components/breadcrumb/breadcrumb.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'app-header-page',
	templateUrl: './header-page.component.html',
	styleUrls: ['./header-page.component.scss'],
})
export class HeaderPageComponent {
	@Input() showImage: boolean = false;
	@Input() element: any;
	@Input() breadcrumb: any = false;
	@Input() mode = 'dark';

	@Output() eventCategoryClick: EventEmitter<any> = new EventEmitter();
}

/*================================================================
# MODULE
================================================================*/
@NgModule({
	declarations: [HeaderPageComponent],
	imports: [CommonModule, TranslateModule, BreadcrumbModule],
	exports: [HeaderPageComponent],
})
export class HeaderPageModule {}
